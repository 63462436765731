import React, { useEffect } from 'react'
import OnboardingStepOneHome from '../OnboardingStepOneHome/OnboardingStepOneHome'
import OnboardingStepTwoSurvey from '../OnboardingStepTwoSurvey/OnboardingStepTwoSurvey'
import OnboardingStepThreeLibrary from '../OnboardingStepThreeLibrary/OnboardingStepThreeLibrary'
import OnboardingStepFourEmail from '../OnboardingStepFourEmail/OnboardingStepFourEmail'
import OnboardingStepFiveNotifications from '../OnboardingStepFiveNotifications/OnboardingStepFiveNotifications'
import OnboardingStepSixAnalytics from '../OnboardingStepSixAnalytics/OnboardingStepSixAnalytics'
import OnboardingStepSevenSettings from '../OnboardingStepSevenSettings/OnboardingStepSevenSettings'
import OnboardingIndex from '../OnboardingIndex/OnboardingIndex'
import { useStep } from 'context/stepContext'
import { updateUserInfo, useGetUserInfoQuery } from 'services/users'
import { useLocation } from 'react-router-dom'
import { ResetAllStepsAction } from 'reducers/onboardingReducer'

export default function OnboardingMain() {

    const location = useLocation();
    const {
        data: user,
	} = useGetUserInfoQuery();
    const { state, dispatch } = useStep();

    useEffect(() => {
        if(user?.show_onboarding){
            dispatch(ResetAllStepsAction());
            
            const values = {
                username: user.username,
                first_name: user.first_name, 
                last_name: user.last_name, 
                email: user.email, 
                show_onboarding: false, 
            }
            
            updateUserInfo(values)
        }
    }, [user])

    let stepComponent = null;

    if (state.should_show_onboarding) {
        switch (true) {
            case !state.index:
                stepComponent = <OnboardingIndex />;
                break;
            case !state.stepone:
                stepComponent = <OnboardingStepOneHome />;
                break;
            case !state.steptwo && location.pathname.includes('/surveys/'):
                stepComponent = <OnboardingStepTwoSurvey />;
                break;
            case !state.stepthree && location.pathname === '/templates':
                stepComponent = <OnboardingStepThreeLibrary />;
                break;
            case !state.stepfour && location.pathname === '/emails':
                stepComponent = <OnboardingStepFourEmail />;
                break;
            case !state.stepfive && location.pathname === '/notifications':
                stepComponent = <OnboardingStepFiveNotifications />;
                break;
            case !state.stepsix && location.pathname === '/analytics':
                stepComponent = <OnboardingStepSixAnalytics />;
                break;
            case !state.stepseven && location.pathname === '/profile':
                stepComponent = <OnboardingStepSevenSettings />;
                break;
            default:
                stepComponent = null;
        }
    }

    return <>{stepComponent}</>;
}