import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';

import { useAlertDispatchContext, useUserDispatchContext } from 'context';
import { userLogout } from 'services/auth';
import { alertShowAction } from 'reducers';

import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { ReactComponent as SvgLogo } from 'assets/icons/logo.svg';
import { ReactComponent as SvgLogout } from 'assets/icons/logout.svg';
import { ReactComponent as SvgChevron } from 'assets/icons/chevron.svg';
import { ReactComponent as SvgSurveys } from 'assets/icons/clipboard-icon.svg';
import { ReactComponent as SvgEmails } from 'assets/icons/email.svg';
import { ReactComponent as SvgNotifications } from 'assets/icons/bell.svg';
import { ReactComponent as SvgAnalytics } from 'assets/icons/analytics.svg';
// library commented
// import { ReactComponent as SvgTemplate } from 'assets/icons/template.svg';
import { ReactComponent as SvgCog } from 'assets/icons/cog.svg';
import { ReactComponent as Help } from 'assets/icons/help.svg';
export interface ILayoutProps {
	pageTitle: string | JSX.Element;
	headerComponent?: JSX.Element;
	withBack?: boolean;
	fullPageMode?: boolean;
	wider?: boolean;
}

export const Layout: React.FC<ILayoutProps> = ({
	pageTitle,
	headerComponent,
	children,
	withBack,
	fullPageMode,
	wider
}) => {
	const navigate = useNavigate();
	const userDispatch = useUserDispatchContext();

	const [sidebarOpen, setSidebarOpen] = useState(false);

	const currentPath = window.location.pathname.split('/')[1];

	const navigation: {
		name: string;
		href: string;
		icon: JSX.Element;
		current: boolean;
		external?: boolean;
		className?: string;
	}[] = [
		{
			name: 'Surveys',
			href: '/surveys',
			icon: <SvgSurveys className="shrink-0 h-6 w-6" />,
			current: currentPath === 'surveys'
		},
		// library commented
		// {
		// 	name: 'Library',
		// 	href: '/templates',
		// 	icon: <SvgTemplate className="shrink-0 h-6 w-6" />,
		// 	current: currentPath === 'templates'
		// },
		{
			name: 'Emails',
			href: '/emails',
			icon: <SvgEmails className="shrink-0 h-6 w-6" />,
			current: currentPath === 'emails'
		},
		{
			name: 'Notifications',
			href: '/notifications',
			icon: <SvgNotifications className="shrink-0 h-6 w-6" />,
			current: currentPath === 'notifications'
		},
		{
			name: 'Analytics',
			href: '/analytics',
			icon: <SvgAnalytics className="shrink-0 h-6 w-6" />,
			current: currentPath === 'analytics'
		},
		{
			name: 'Help',
			href: '/help',
			icon: <Help className="shrink-0 h-6 w-6" />,
			current: currentPath === 'help',
			className: 'mt-auto'
		},
		{
			name: 'Settings',
			href: '/profile',
			icon: <SvgCog className="shrink-0 h-6 w-6" />,
			current: currentPath === 'profile',
		}
	];

	const alertDispatch = useAlertDispatchContext();

	const handleLogOut = () => {
		alertDispatch(
			alertShowAction({
				header: `Logout`,
				message: `Are you sure you want to logout?`,

				buttons: [
					{
						text: `Close`,
						handler: () => null,
						type: 'light'
					},
					{
						text: `Logout`,
						handler: () => userLogout(userDispatch)
					}
				]
			})
		);
	};

	return (
		<div className="print:hidden">
			<Transition.Root show={sidebarOpen} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 flex z-40 md:hidden"
					onClose={setSidebarOpen}
				>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
					</Transition.Child>
					<Transition.Child
						as={Fragment}
						enter="transition ease-in-out duration-300 transform"
						enterFrom="-translate-x-full"
						enterTo="translate-x-0"
						leave="transition ease-in-out duration-300 transform"
						leaveFrom="translate-x-0"
						leaveTo="-translate-x-full"
					>
						<div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
							<Transition.Child
								as={Fragment}
								enter="ease-in-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in-out duration-300"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<div className="absolute top-0 right-0 -mr-12 pt-2">
									<button
										type="button"
										className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
										onClick={() => setSidebarOpen(false)}
									>
										<span className="sr-only">Close sidebar</span>
										<XIcon className="h-6 w-6 text-white" aria-hidden="true" />
									</button>
								</div>
							</Transition.Child>
							<div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
								<div className="flex items-center flex-shrink-0 px-4">
									<SvgLogo className="w-9 h-9 text-white" />
									<h2 className="text-base uppercase text-white font-bold pl-4">
										Hospitality Insights
									</h2>
								</div>
								<nav className="mt-5 px-2 space-y-1">
									{navigation.map(item => {
										return (
											<React.Fragment key={item.name}>
												{(item.external && (
													<a
														key={item.name}
														href={item.href}
														target="_blank"
														rel="noopener noreferrer"
														className={`group flex items-center px-2 py-2 text-sm font-medium rounded-md
															${
																item.current
																	? 'bg-blue-600 text-white'
																	: 'text-gray-300 hover:bg-blue-700 hover:text-white'
															}
															${item.className || ''}
														`}
													>
														{item.icon}
														{item.name}
													</a>
												)) || (
													<Link
														key={item.name}
														to={item.href}
														className={`group flex items-center px-2 py-2 text-sm font-medium rounded-md
															${
																item.current
																	? 'bg-blue-600 text-white'
																	: 'text-gray-300 hover:bg-blue-700 hover:text-white'
															}
															${item.className || ''}
														`}
													>
														{item.icon}
														{item.name}
													</Link>
												)}
											</React.Fragment>
										);
									})}
								</nav>
							</div>

							<button
								onClick={handleLogOut}
								className="flex-shrink-0 flex bg-gray-700 p-4 group hover:bg-blue-700"
							>
								<div className="flex items-center">
									<SvgLogout className="text-gray-400 group-hover:text-white" />

									<div className="ml-3 text-left">
										<p className="text-sm font-medium text-gray-400 group-hover:text-white">
											Logout
										</p>
									</div>
								</div>
							</button>
						</div>
					</Transition.Child>
					<div className="flex-shrink-0 w-14">
						{/* Force sidebar to shrink to fit close icon */}
					</div>
				</Dialog>
			</Transition.Root>
			{/* Static sidebar for desktop */}
			<div
				className="hidden md:flex md:flex-col md:fixed md:inset-y-0 transition-all duration-200 z-50
					md:hover:w-56 md:w-20 group xxl:w-56"
			>
				{/* Sidebar component, swap this element with another sidebar if you like */}
				<div className="flex-1 flex flex-col min-h-0 bg-gray-800">
					<div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto overflow-x-hidden">
						<div className="flex items-center flex-shrink-0 px-4 h-12">
							<SvgLogo className="w-9 h-9 text-white shrink-0 mx-1.5 group-hover:mr-0 transition-all duration-100 xxl:mr-0" />
							<h2 className="text-base uppercase text-white font-bold pl-4 opacity-0 group-hover:opacity-100 invisible group-hover:visible pointer-events-none group-hover:pointer-events-auto transition-all duration-100 xxl:opacity-100 xxl:visible xxl:pointer-events-auto">
								Hospitality Insights
							</h2>
						</div>
						<nav className="flex flex-col mt-5 flex-1 px-2">
							{navigation.map(item => {
								return (
									<React.Fragment key={item.name}>
										{(item.external && (
											<a
												key={item.name}
												href={item.href}
												target="_blank"
												rel="noopener noreferrer"
												className={`flex items-center px-2 py-2 text-sm font-medium rounded-md
													${
														item.current
															? 'bg-blue-600 text-white'
															: 'text-gray-300 hover:bg-blue-700 hover:text-white'
													}
													${item.className || ''}
												`}
											>
												<span className="mx-3 group-hover:mr-0 xxl:mr-0 transition-all duration-100">
													{item.icon}
												</span>
												<span className="opacity-0 group-hover:opacity-100 invisible group-hover:visible pointer-events-none group-hover:pointer-events-auto ml-5 transition-all duration-100 xxl:opacity-100 xxl:visible xxl:pointer-events-auto">
													{item.name}
												</span>
											</a>
										)) || (
											<Link
												key={item.name}
												to={item.href}
												className={`flex items-center px-2 py-2 text-sm font-medium rounded-md
													${
														item.current
															? 'bg-blue-600 text-white'
															: 'text-gray-300 hover:bg-blue-700 hover:text-white'
													}
													${item.className || ''}
												`}
											>
												<span className="mx-3 group-hover:mr-0 xxl:mr-0 transition-all duration-100">
													{item.icon}
												</span>
												<span className="opacity-0 group-hover:opacity-100 invisible group-hover:visible pointer-events-none group-hover:pointer-events-auto ml-5 transition-all duration-100 xxl:opacity-100 xxl:visible xxl:pointer-events-auto">
													{item.name}
												</span>
											</Link>
										)}
									</React.Fragment>
								);
							})}
						</nav>
					</div>

					<button
						onClick={handleLogOut}
						className="flex items-center flex-shrink-0 bg-gray-700 p-4 group hover:bg-blue-700 text-gray-300 hover:text-white"
					>
						<SvgLogout className="text-inherit shrink-0 mx-3 group-hover:mr-0 xxl:mr-0 transition-all duration-100" />
						<div className="ml-5 text-left opacity-0 group-hover:opacity-100 invisible group-hover:visible pointer-events-none group-hover:pointer-events-auto transition-all duration-100 xxl:opacity-100 xxl:visible xxl:pointer-events-auto">
							<p className="text-xs font-medium text-inherit">Logout</p>
						</div>
					</button>
				</div>
			</div>

			<div className="md:pl-20 xxl:pl-56 flex flex-col flex-1">
				{fullPageMode ? (
					children
				) : (
					<>
						<div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
							<button
								type="button"
								className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-700"
								onClick={() => setSidebarOpen(true)}
							>
								<span className="sr-only">Open sidebar</span>
								<MenuIcon className="h-6 w-6" aria-hidden="true" />
							</button>
						</div>
						<main className="flex-1">
							<div
								className={`py-6 mx-auto px-4 sm:px-6 md:px-8 ${
									wider ? 'max-w-7xl' : 'max-w-5xl'
								}`}
							>
								<header className="relative flex justify-between border-b border-transparent pb-6">
									<div className="flex">
										{withBack && (
											<button
												type="button"
												aria-label="back to surveys"
												className="w-10 h-10 flex items-center hover:text-blue-700"
												onClick={() => navigate('/surveys')}
											>
												<SvgChevron className="rotate-180 w-5 h-5" />
											</button>
										)}

										<h1 className="flex items-center text-2xl font-semibold text-gray-900">
											{pageTitle}
										</h1>
									</div>
									{headerComponent}
								</header>
								<div className="pb-32">{children}</div>
							</div>
						</main>
					</>
				)}
			</div>
		</div>
	);
};
