import React from 'react';
import { FieldArray, FieldHelperProps } from 'formik';

import { Button } from 'components/common';
import {
	FieldDatePicker,
	FieldInput,
	FieldSelect
} from 'components/formik-elements';

import { accessPropertyByStringPath, range } from 'utils';
import { ISurveyUI, IQuestion } from 'services/surveys/interfaces';
import { useLanguageContext } from 'context';
import {
	getAllQuestions,
	getQuestionByName,
	getQuestionChoices,
	getQuestionConditionOptions,
	getQuestionOptions,
	questionConditions,
	requiresSkipLogicAnswer,
	resetAnswerOnChange,
	resetConditionOnChange
} from 'components/survey/helpers';

import { ReactComponent as SvgPlus } from 'assets/icons/plus.svg';
import { ReactComponent as SvgClose } from 'assets/icons/close.svg';

interface ISurveySkipLogicProps {
	survey: ISurveyUI;
	item: IQuestion;
	index: number;
	groupName: string;
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>;
}

export const SurveySkipLogic: React.FC<ISurveySkipLogicProps> = ({
	survey,
	item,
	index,
	groupName,
	getFieldHelpers
}) => {
	const { currentLanguage, defaultLanguage } = useLanguageContext();

	return (
		<div className="space-y-6">
			<FieldArray
				name={`${groupName}.${index}.skip_logic`}
				render={props => (
					<>
						{item.skip_logic?.map((condition, conditionIndex) => {
							const questionType = getQuestionByName(
								condition.question,
								getAllQuestions(survey)
							)?.type;
							const appearanceType = getQuestionByName(
								condition.question,
								getAllQuestions(survey)
							)?.appearance;

							const todayYear = new Date().getFullYear();

							return (
								<div key={conditionIndex} className="flex">
									<div className="flex flex-wrap flex-grow -mx-2">
										<FieldSelect
											className="w-1/3 flex-none px-2"
											name={`${groupName}.${index}.skip_logic.${conditionIndex}.question`}
											options={getQuestionOptions(
												getAllQuestions(survey),
												currentLanguage,
												item
											)}
											onChange={() =>
												{
												
												resetConditionOnChange(
													index,
													conditionIndex,
													groupName,
													getFieldHelpers
												);
												}
											}
											initialValue={{
												value: condition.question,
												display: getQuestionByName(
													condition.question,
													getAllQuestions(survey)
												)?.label?.[currentLanguage]
											}}
										/>

										<FieldSelect
											className={`w-1/3 flex-none px-2`}
											name={`${groupName}.${index}.skip_logic.${conditionIndex}.operator`}
											options={getQuestionConditionOptions(
												condition.question,
												getAllQuestions(survey)
											)}
											onChange={choice =>
												resetAnswerOnChange(
													choice,
													index,
													conditionIndex,
													groupName,
													getFieldHelpers
												)
											}
											initialValue={{
												value: condition.operator,
												display: questionConditions[condition.operator]
											}}
											disabled={condition.question === ''}
										/>

										{((questionType === 'select_one' || questionType === 'select_multiple' || questionType === 'property' ||
											questionType === 'sex' || questionType === 'matrix_rating' || questionType === 'select_one_img' || 
											questionType === 'select_multiple_img') &&
											!requiresSkipLogicAnswer(condition.operator) && (
												<FieldSelect
													className={`w-1/3 flex-none px-2`}
													name={`${groupName}.${index}.skip_logic.${conditionIndex}.answer`}
													options={getQuestionChoices(
														condition.question,
														getAllQuestions(survey),
														currentLanguage,
														defaultLanguage
													)}
												/>
											)) ||
											(questionType === 'emoji_rating' &&
												!requiresSkipLogicAnswer(condition.operator) && (
													<FieldSelect
														className={`w-1/3 flex-none px-2`}
														name={`${groupName}.${index}.skip_logic.${conditionIndex}.answer`}
														options={[
															{
																value: 'choice_0',
																display: 'Sad'
															},
															{
																value: 'choice_1',
																display: 'Neutral'
															},
															{
																value: 'choice_2',
																display: 'Smile'
															},
															{
																value: 'choice_3',
																display: 'Extatic'
															}
														]}
													/>
												)) ||
											(questionType === 'rating' &&
												!requiresSkipLogicAnswer(condition.operator) && (
													<FieldSelect
														className={`w-1/3 flex-none px-2`}
														name={`${groupName}.${index}.skip_logic.${conditionIndex}.answer`}
														options={[
															{
																value: 'choice_0',
																display: '1'
															},
															{
																value: 'choice_1',
																display: '2'
															},
															{
																value: 'choice_2',
																display: '3'
															},
															{
																value: 'choice_3',
																display: '4'
															},
															{
																value: 'choice_4',
																display: '5'
															}
														]}
													/>
												)) ||
											(questionType === 'nps' &&
												!requiresSkipLogicAnswer(condition.operator) && (
													<FieldSelect
														className={`w-1/3 flex-none px-2`}
														name={`${groupName}.${index}.skip_logic.${conditionIndex}.answer`}
														options={[
															{
																value: 'choice_0',
																display: '0'
															},
															{
																value: 'choice_1',
																display: '1'
															},
															{
																value: 'choice_2',
																display: '2'
															},
															{
																value: 'choice_3',
																display: '3'
															},
															{
																value: 'choice_4',
																display: '4'
															},
															{
																value: 'choice_5',
																display: '5'
															},
															{
																value: 'choice_6',
																display: '6'
															},
															{
																value: 'choice_7',
																display: '7'
															},
															{
																value: 'choice_8',
																display: '8'
															},
															{
																value: 'choice_9',
																display: '9'
															},
															{
																value: 'choice_10',
																display: '10'
															}
														]}
													/>
												)) ||
											(questionType === 'date' &&
												appearanceType === 'no-calendar' && (
													<FieldDatePicker
														name={`${groupName}.${index}.skip_logic.${conditionIndex}.answer`}
														className={`w-1/3 flex-none px-2`}
														value={condition.answer}
														disabled={requiresSkipLogicAnswer(
															condition.operator
														)}
													/>
												)) ||
											(questionType === 'date' &&
												appearanceType === 'month-year' && (
													<FieldInput
														placeholder="yyyy-mm"
														name={`${groupName}.${index}.skip_logic.${conditionIndex}.answer`}
														className={`w-1/3 flex-none px-2`}
														value={condition.answer}
														disabled={requiresSkipLogicAnswer(
															condition.operator
														)}
														pattern="^((1|2)\d{3})-(0[1-9]{1}|1[0-2]{1})$"
														title="yyyy-mm"
													/>
												)) ||
											(questionType === 'date' && appearanceType === 'year' && (
												<FieldSelect
													className={`w-1/3 flex-none px-2`}
													name={`${groupName}.${index}.skip_logic.${conditionIndex}.answer`}
													options={range(todayYear - 100, todayYear + 11).map(
														item => {
															return {
																value: item,
																display: String(item)
															};
														}
													)}
													disabled={requiresSkipLogicAnswer(condition.operator)}
												/>
											)) ||
											(questionType === 'integer' && (
												<FieldInput
													placeholder="answer"
													className={`w-1/3 flex-none px-2`}
													id={`${groupName}.${index}.skip_logic.${conditionIndex}.answer`}
													name={`${groupName}.${index}.skip_logic.${conditionIndex}.answer`}
													value={condition.answer}
													disabled={requiresSkipLogicAnswer(condition.operator)}
													type="text"
													pattern="^\d*$"
												/>
											)) || (
												<FieldInput
													placeholder="answer"
													className={`w-1/3 flex-none px-2`}
													id={`${groupName}.${index}.skip_logic.${conditionIndex}.answer`}
													name={`${groupName}.${index}.skip_logic.${conditionIndex}.answer`}
													value={condition.answer}
													disabled={requiresSkipLogicAnswer(condition.operator)}
												/>
											)}
									</div>
									<button
										type="button"
										onClick={() => props.remove(conditionIndex)}
										className="flex-none w-8 h-8 p-2.5 ml-4 mt-1 text-white text-lg flex justify-center items-center bg-blue-700 hover:bg-blue-600 rounded-full"
									>
										<SvgClose />
									</button>
								</div>
							);
						})}
						{accessPropertyByStringPath(props.form.values, props.name)?.length >
							1 && (
							<FieldSelect
								name={`${groupName}.${index}.skip_logic_operator`}
								label="How should these conditions be combined?"
								options={[
									{
										value: 'and',
										display: 'All conditions must be met'
									},
									{
										value: 'or',
										display: 'Any condition can be met'
									}
								]}
							/>
						)}
						<Button
							type="button"
							onClick={() =>
								props.push({
									question: '',
									operator: '!=""'
								})
							}
							size="small"
							iconLeft={<SvgPlus />}
						>
							Add {item.skip_logic?.length ? 'another' : 'a'} condition
						</Button>
					</>
				)}
			/>
		</div>
	);
};
