import { useStep } from 'context/stepContext';
import React, { useState } from 'react'
import { stepSetAction } from 'reducers/onboardingReducer';
import { Switch } from "@/components/ui/switch"

export default function OnboardingStepTwoSurvey() {

    const [checked, setChecked] = useState<boolean>(false)
    
    const { dispatch } = useStep();

    function onContinue(){
        if(checked){
            dispatch(stepSetAction({ should_show_onboarding: false }));
        } else {
            dispatch(stepSetAction({ steptwo: true }));
        }
    }

    return (
        <div className='absolute inset-0 flex bg-black/50 z-50 justify-center items-center px-10'>
            <div className='max-w-sm p-4 rounded-lg flex flex-col bg-[#F5F5F5] gap-14 text-sm sm:text-base'>
                <div className='flex flex-col gap-4'>
                    <p>Survey page is for <strong>managing selected survey</strong>.</p>
                    <div>
                        <p><strong>Status</strong></p>
                        <div className='flex flex-col gap-2'>
                            <div className='p-2 border border-gray-400 rounded-lg'>
                                <div className='flex'>
                                    <p className='text-red-900 rounded-full bg-red-100 py-1 px-4'>Closed</p>
                                </div>
                                <ul className='list-disc pl-6'>
                                    <li>print your past submissions</li>
                                </ul>
                            </div>
                            <div className='p-2 border border-gray-400 rounded-lg'>
                                <div className='flex'>
                                    <p className='text-yellow-900 rounded-full bg-yellow-100 py-1 px-4'>Draft</p>
                                </div>
                                <ul className='list-disc pl-6'>
                                    <li>Edit your survey content</li>
                                    <li>Choose analytic types</li>
                                    <li>See how would survey look</li>
                                    <li>Once you are finished editing, publish</li>
                                </ul>
                            </div>
                            <div className='p-2 border border-gray-400 rounded-lg'>
                                <div className='flex'>
                                    <p className='text-green-900 rounded-full bg-green-100 py-1 px-4'>Draft</p>
                                </div>
                                <ul className='list-disc pl-6'>
                                    <li>Everything like draft</li>
                                    <li>View your submissions</li>
                                    <li>View access link</li>
                                    <li>Create access QR code</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-4 justify-end items-end'>
                    <div className="flex space-x-2 flex-col sm:flex-row justify-end items-end">
                        <label htmlFor="off" className='text-sm order-1 sm:order-none text-right'>Turn off onboarding</label>
                        <Switch 
                            id="off" 
                            checked={checked}
                            onCheckedChange={(isChecked:boolean) => setChecked(isChecked)}
                        />
                    </div>
                    <div className='flex justify-end'>
                        <button className="bg-[#004E96] p-2 px-4 rounded-lg text-sm sm:text-base text-white ml-4 sm:ml-0" onClick={onContinue}>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
