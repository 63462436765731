import { createAction } from './actions/actions';

export interface IStepState {
    should_show_onboarding: boolean;
    index: boolean;
    stepone: boolean;
    steptwo: boolean;
    stepthree: boolean;
    stepfour: boolean;
    stepfive: boolean;
    stepsix: boolean;
    stepseven: boolean;
}

export const initialStepState: IStepState = {
    should_show_onboarding: false,
    index: false,
    stepone: false,
    steptwo: false,
    stepthree: false,
    stepfour: false,
    stepfive: false,
    stepsix: false,
    stepseven: false
};

export enum StepActions {
    StepSet = '[StepContext] Set Step',
    ResetAll = '[StepContext] Toggle All Steps'
}

export type StepActionTypes = IStepSetAction | IToggleAllStepsAction;

interface IStepSetAction {
    type: typeof StepActions.StepSet;
    payload: Partial<IStepState>;
}

interface IToggleAllStepsAction {
    type: typeof StepActions.ResetAll;
}

export const stepSetAction = createAction<IStepSetAction['payload']>(
    StepActions.StepSet
);

export const ResetAllStepsAction = createAction(
    StepActions.ResetAll
);

export const stepReducer = (state: IStepState, action: StepActionTypes) => {
    switch (action.type) {
        case StepActions.StepSet: {
            return { ...state, ...action.payload };
        }
        case StepActions.ResetAll: {
            return {
                ...state,
                should_show_onboarding: true,
                index: false,
                stepone: false,
                steptwo: false,
                stepthree: false,
                stepfour: false,
                stepfive: false,
                stepsix: false,
                stepseven: false,
            };
        }
        default: {
            throw new Error('StepReducer: This reducer action does not exist');
        }
    }
};

