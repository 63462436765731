import React from 'react';
import { FieldArray, FieldArrayRenderProps, FieldHelperProps } from 'formik';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { Button } from 'components/common';
import { FieldInput } from 'components/formik-elements';
import { SurveySkipLogic, SurveyQuestion } from 'components/survey';

import {
	useAlertDispatchContext,
	useLanguageContext,
	useSurveyCollapseContext,
	useSurveyCollapseDispatchContext
} from 'context';
import { alertShowAction, toggleSection } from 'reducers';
import { ISurveySection, ISurveyUI } from 'services/surveys/interfaces';
import {
	addQuestionToSection,
	deleteSection,
	getAllQuestions,
	getQuestionIndex,
	prepareQuestions
} from 'components/survey/helpers';

import { ReactComponent as SvgDropdown } from 'assets/icons/chevron.svg';
import { ReactComponent as SvgDelete } from 'assets/icons/trash.svg';
import { ReactComponent as SvgPlus } from 'assets/icons/plus.svg';
import { ReactComponent as SvgEdit } from 'assets/icons/edit.svg';
import { EditEveryLanguageModal } from '../EditEveryLanguageModal';
import { AddFromTemplateModal } from 'components/template/AddFromTemplateModal';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { getPropertiesOptions } from 'components/property/helpers/propertyHelper';

interface ISurveySectionProps {
	index: number;
	section: ISurveySection;
	survey: ISurveyUI;
	errors: any;
	arrayHelpers?: any;
	properties: any;
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>;
}
export const SurveySection: React.FC<ISurveySectionProps> = ({
	index,
	section,
	survey,
	errors,
	arrayHelpers,
	properties,
	getFieldHelpers
}) => {
	const [animationParent] = useAutoAnimate({
		duration: 500
	});
	const surveyExpandDispatch = useSurveyCollapseDispatchContext();
	const { sections: expandedSections } = useSurveyCollapseContext();
	const alertDispatch = useAlertDispatchContext();
	const { currentLanguage, availableLanguages, defaultLanguage } =
		useLanguageContext();

	const [tabIndex, setTabIndex] = React.useState<number>(0);
	React.useEffect(() => {
		if (
			getAllQuestions(survey).filter(
				q =>
					q.label?.[currentLanguage] &&
					!section.questions.some(q2 => q2.name === q.name)
			).length === 0
		) {
			setTabIndex(0);
		}
	}, [currentLanguage]);

	React.useEffect(() => {
		if (
			section.questions.length === 0 &&
			index + 1 === survey.sections.length
		) {
			deleteSection(section.questions, arrayHelpers, index);
		}
	}, [section.questions.length]);

	const hasQuestions = React.useMemo(() => {
		return (
			getAllQuestions(survey).filter(
				q =>
					q.label?.[currentLanguage] &&
					!section.questions.some(q2 => q2.name === q.name)
			).length > 0
		);
	}, [survey, currentLanguage]);

	const isCollapsed = React.useMemo(
		() => expandedSections.some(s => s === section.name),
		[expandedSections, section]
	);

	const orderUp = (arrayHelpers: FieldArrayRenderProps, index: number) => {
		arrayHelpers.move(index, index - 1);
	};

	const orderDown = (arrayHelpers: FieldArrayRenderProps, index: number) => {
		arrayHelpers.move(index, index + 1);
	};

	const handleDelete = () => {
		alertDispatch(
			alertShowAction({
				header: `Delete section?`,
				message: `Are you sure you want to delete this section? Questions from this section are moved to general questions upon section deletion.`,

				buttons: [
					{
						text: `Close`,
						type: 'light'
					},
					{
						text: `Confirm`,
						handler: () => deleteSection(section.questions, arrayHelpers, index)
					}
				]
			})
		);
	};

	//handle editEveryLanguage
	const [editEveryLanguageModal, setEditEveryLanguageModal] = React.useState<
		Record<string, any>
	>({
		isOpen: false,
		item: '',
		itemType: ''
	});

	//handle addFromTemplate
	const [addFromTemplateModal, setAddFromTemplateModal] = React.useState<
		Record<string, any>
	>({
		isOpen: false,
		section: null
	});

	const actionButtons = (survey?: ISurveyUI) => {
		return (
			<div className={`flex items-center justify-between ml-4 mt-9 h-min`}>
				<button
					type="button"
					onClick={() => surveyExpandDispatch(toggleSection(section.name))}
					className="flex items-center justify-center mr-2 w-5 h-5 hover:text-blue-700"
				>
					<SvgDropdown
						className={`${isCollapsed ? '' : 'rotate-90'} transition-transform`}
					/>
				</button>

				{availableLanguages.length > 1 && (
					<button
						type="button"
						onClick={() =>
							setEditEveryLanguageModal({
								isOpen: true,
								item: section,
								itemType: 'section'
							})
						}
						className="flex items-center justify-center mr-2 w-5 h-5 hover:text-blue-700"
					>
						<SvgEdit />
					</button>
				)}

				<button
					type="button"
					onClick={() => handleDelete()}
					className={`flex items-center justify-center w-5 h-5 hover:text-red-500 ${
						survey?.submissions_count &&
						survey?.submissions_count !== 0 &&
						section.question_id !== undefined
							? 'pointer-events-none text-gray-300'
							: ''
					}`}
					disabled={
						survey?.submissions_count &&
						survey?.submissions_count !== 0 &&
						section.question_id !== undefined
							? true
							: false
					}
				>
					<SvgDelete />
				</button>
			</div>
		);
	};

	const reorderButtons = (
		<div className="flex flex-col h-min mr-4 w-4">
			<button
				tabIndex={index === 0 ? -1 : 0}
				type="button"
				className={`flex items-center justify-center w-3 ${
					index === 0
						? 'text-gray-300 hover:text-gray-300 cursor-auto pointer-events-none'
						: 'hover:text-blue-700'
				}`}
				onClick={() => orderUp(arrayHelpers, index)}
			>
				<SvgDropdown className="-rotate-90" />
			</button>
			<button
				tabIndex={
					index === arrayHelpers.form.values.sections.length - 1 ? -1 : 0
				}
				type="button"
				className={`flex items-center justify-center w-3 ${
					index === arrayHelpers.form.values.sections.length - 1
						? 'text-gray-300 hover:text-gray-300 cursor-auto pointer-events-none'
						: 'hover:text-blue-700'
				}`}
				onClick={() => orderDown(arrayHelpers, index)}
			>
				<SvgDropdown className="rotate-90" />
			</button>
		</div>
	);

	return (
		<>
			<div>
				<div className="mt-12 mb-6 flex flex-row-reverse">
					<div className="flex w-full">
						<FieldInput
							className="flex-1"
							label={<span className="font-bold">Section #{index + 1}</span>}
							name={`sections.${index}.label.${currentLanguage}`}
							value={section.label?.[currentLanguage]}
							error={
								arrayHelpers.form.submitCount && errors?.sections?.[index]
									? errors?.sections[index]?.label?.[currentLanguage]
									: undefined
							}
							placeholder={
								(section.label?.[defaultLanguage] &&
									section.label?.[defaultLanguage]) ||
								''
							}
						/>
						{actionButtons(survey)}
					</div>
					{reorderButtons}
				</div>

				{!isCollapsed && (
					<>
						<Tabs
							selectedIndex={tabIndex}
							onSelect={index => setTabIndex(index)}
						>
							<TabList>
								<Tab>Questions</Tab>
								<Tab disabled={!hasQuestions}>Skip Logic</Tab>
							</TabList>

							<TabPanel>
								<div className="w-full" ref={animationParent as any}>
									<FieldArray
										name={`sections.${index}.questions`}
										render={arrayHelpers => {
											return (
												<>
													{section.questions.map((question, q_index) => {
														return (
															<SurveyQuestion
																groupName={`sections.${index}.questions`}
																key={question.name}
																indexInGroup={q_index}
																questionIndex={getQuestionIndex(
																	question,
																	survey
																)}
																formValues={survey}
																properties={getPropertiesOptions(null,properties?.results)}
																{...{
																	arrayHelpers,
																	getFieldHelpers,
																	errors:
																		errors?.sections?.[index]?.questions?.[
																			q_index
																		],
																	question
																}}
															/>
														);
													})}
												</>
											);
										}}
									/>
								</div>
							</TabPanel>
							<TabPanel>
								<div className="first-letter:flex px-12 py-6 border rounded-lg border-gray-300 bg-gray-50">
									<p className="text-sm mb-5">
										This section will only be displayed if the following
										conditions apply
									</p>
									<SurveySkipLogic
										getFieldHelpers={getFieldHelpers}
										groupName={`sections`}
										index={index}
										survey={survey}
										item={section}
									/>
								</div>
							</TabPanel>
						</Tabs>

						{survey.sections.length - 1 !== index && (
							<Button
								className="mr-3 mt-6 mb-6"
								type="button"
								text="New question"
								size="small"
								variant="dark"
								iconLeft={<SvgPlus />}
								onClick={() =>
									addQuestionToSection(getFieldHelpers, survey, index)
								}
								/* disabled={
									survey.submissions_count && survey?.submissions_count !== 0
										? true
										: false
								} */
							/>
						)}

						{/* library commented
							{survey.sections.length - 1 !== index && (
							<Button
								className="mr-2"
								type="button"
								text="Add from library"
								size="small"
								iconLeft={<SvgPlus />}
								variant="dark"
								onClick={() =>
									setAddFromTemplateModal({
										isOpen: true,
										section: 'section_' + index
									})
								}
								// disabled={
								// 	survey.submissions_count && survey?.submissions_count !== 0
								// 		? true
								// 		: false
								// } 
							/>
						)} */}
					</>
				)}
			</div>

			<EditEveryLanguageModal
				isOpen={editEveryLanguageModal.isOpen}
				onRequestClose={() =>
					setEditEveryLanguageModal({
						isOpen: false,
						item: '',
						itemType: ''
					})
				}
				item={editEveryLanguageModal.item}
				itemType={editEveryLanguageModal.itemType}
				sectionIndex={index}
			/>

			<AddFromTemplateModal
				isOpen={addFromTemplateModal.isOpen}
				onRequestClose={() =>
					setAddFromTemplateModal({
						isOpen: false,
						section: null
					})
				}
				surveyLanguages={availableLanguages}
				onSubmit={values => {
					prepareQuestions(values, survey).map(question => {
						return addQuestionToSection(
							getFieldHelpers,
							survey,
							index,
							question
						);
					});
				}}
			/>
		</>
	);
};
