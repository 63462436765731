import { Button } from 'components/common'
import { ReactComponent as SvgPlus } from 'assets/icons/plus.svg';
import React, { useState } from 'react'
import { useStep } from 'context/stepContext';
import { stepSetAction } from 'reducers/onboardingReducer';
import { Switch } from "@/components/ui/switch"

export default function OnboardingStepFiveNotifications() {

    const [checked, setChecked] = useState<boolean>(false)
    
    const { dispatch } = useStep();

    function onContinue(){
        if(checked){
            dispatch(stepSetAction({ should_show_onboarding: false }));
        } else {
            dispatch(stepSetAction({ stepfive: true }));
        }
    }

    return (
        <div className='absolute inset-0 flex bg-black/50 z-50 justify-center items-center px-10'>
            <div className='max-w-sm p-4 rounded-lg flex flex-col bg-[#F5F5F5] gap-14 text-sm sm:text-base'>
                <div className='flex flex-col gap-4 text-sm sm:text-base'>
                    <p>On <strong>notifications</strong> page click:</p>
                    <div>
                        <Button
                            text="New rule"
                            size="small"
                            iconLeft={<SvgPlus />}
                            href=""
                            className='pointer-events-none'
                        />
                    </div>
                    <p>Rules are <strong>predefined conditions</strong> or <strong>criteria</strong> that trigger the delivery of notifications based on specific conditions you have set. These rules determine <strong>when</strong> notifications are sent, <strong>who</strong> receives them, and <strong>what</strong> information they contain.</p>
                    <p>For example, a rule may be set to send a notification to a manager whenever a <strong>new survey response is submitted</strong>, or to send a notification to a customer support team member <strong>when a negative review is received</strong>.</p>
                </div>
                <div className='flex flex-col gap-4 justify-end items-end'>
                    <div className="flex items-end sm:items-center space-x-2 flex-col sm:flex-row">
                        <label htmlFor="off" className='text-sm order-1 sm:order-none text-right'>Turn off onboarding</label>
                        <Switch 
                            id="off" 
                            checked={checked}
                            onCheckedChange={(isChecked:boolean) => setChecked(isChecked)}
                        />
                    </div>
                    <div className='flex justify-end'>
                        <button className="bg-[#004E96] p-2 px-4 rounded-lg text-sm sm:text-base text-white ml-4 sm:ml-0" onClick={onContinue}>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
