import React, { useState } from 'react'
import { ReactComponent as SvgTrash } from 'assets/icons/trash.svg';
import { ReactComponent as SvgEdit } from 'assets/icons/edit.svg';
import { ReactComponent as SvgPdf } from 'assets/icons/pdf.svg';
import { ReactComponent as SvgEye } from 'assets/icons/eye-on.svg';
import { ReactComponent as SvgCopy } from 'assets/icons/duplicate.svg';
import { ReactComponent as SvgSend } from 'assets/icons/send.svg';
import { useStep } from 'context/stepContext';
import { stepSetAction } from 'reducers/onboardingReducer';
import { Switch } from "@/components/ui/switch"

export default function OnboardingStepOneHome() {

    const [checked, setChecked] = useState<boolean>(false)
    
    const { dispatch } = useStep();

    function onContinue(){
        if(checked){
            dispatch(stepSetAction({ should_show_onboarding: false }));
        } else {
            dispatch(stepSetAction({ stepone: true }));
        }
    }

    return (
        <div className='absolute inset-0 flex bg-black/50 z-50 justify-center items-center px-10'>
            <div className='max-w-sm p-4 rounded-lg flex flex-col bg-[#F5F5F5] gap-14 text-sm sm:text-base'>
                <div className='flex flex-col gap-4'>
                    <p>This is the <strong>main page</strong> where all your <strong>surveys</strong> are displayed</p>
                    <div className="flex gap-4 text-gray-500 px-2 py-6 bg-white justify-center">
                        <SvgEye className="w-5 h-5" />
                        <SvgPdf className="w-5 h-5" />
                        <SvgEdit className="w-5 h-5" />
                        <SvgCopy className="w-5 h-5" />
                        <SvgTrash className="w-5 h-5" />
                        <SvgSend className="w-5 h-5" />
                    </div>
                    <p>Use the <strong>icons to manage</strong> your surveys or click on the survey row to display survey page</p>
                </div>
                <div className='flex flex-col gap-4 justify-end'>
                    <div className="flex items-end sm:items-center space-x-2 flex-col sm:flex-row justify-end">
                        <label htmlFor="off" className='text-sm order-1 sm:order-none text-right'>Turn off onboarding</label>
                        <Switch 
                            id="off" 
                            checked={checked}
                            onCheckedChange={(isChecked:boolean) => setChecked(isChecked)}
                        />
                    </div>
                    <div className='flex justify-end'>
                        <button className="bg-[#004E96] p-2 px-4 rounded-lg text-sm sm:text-base text-white ml-4 sm:ml-0" onClick={onContinue}>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
