import { Layout } from 'components/shared';
import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const info = [
    {
        title: 'Activities',
        description: `Surveys focused on specific activities gather detailed feedback on various elements such as organization, safety, content, pricing, and visitor satisfaction. Organizations can evaluate the effectiveness of events, programs, or services.`,
        subtitles: [
            `Assessing Engagement and Participation`,
            `Improving Content Quality`,
            `Evaluating Logistics and Organization`,
            `Enhancing Safety and Accessibility`,
            `Optimizing Resource Allocation`
        ],
        subdesc: [
            `Activities surveys evaluate the level of participant engagement during events or programs. Feedback on aspects like interaction, enjoyment, and relevance allows organizers to see what worked well and what could be improved. Understanding which elements contributed to a successful experience helps ensure future activities are both engaging and valuable for participants. High levels of engagement are crucial for delivering enjoyable and memorable experiences.`,
            `Surveys provide insights into the quality and effectiveness of the content delivered during the activity. This includes feedback on the clarity, relevance, and presentation of the material. Understanding how well the content was received allows organizations to make necessary adjustments, ensuring that future activities are informative and engaging.`,
            `Feedback on the logistical aspects of an activity, such as scheduling, venue setup, resource allocation, and overall coordination, is crucial for ensuring smooth operations. Gathering insights on how well these elements were managed helps organizations pinpoint any inefficiencies or areas for improvement. For example, participants may highlight issues with timing conflicts, inadequate seating arrangements, or insufficient resources, which can hinder the success of an event or activity. By addressing these concerns and refining logistical planning, businesses can enhance the overall experience, improve operational efficiency, and ensure that future events run more smoothly and are better organized to meet participants' needs.`,
            `Surveys highlight any concerns related to safety or accessibility during the activity. Feedback on these aspects ensures that activities are safe for all participants and accessible to those with disabilities or special needs. Implementing improvements based on this feedback promotes inclusivity and a positive experience for everyone involved.`,
            `Understanding which activities or features are most popular helps organizations allocate resources more effectively. Survey data informs decisions on budget distribution, staff assignments, and material investments, ensuring that resources are used efficiently to enhance the quality and impact of future activities.`,
        ]
    },
    {
        title: 'Gastronomy',
        description: `Gastronomy surveys collect valuable feedback on food and beverage services, allowing organizations to optimize culinary offerings, service quality, and the overall dining experience. Whether for restaurants, corporate cafeterias, event catering, or hospitality businesses, this feedback is crucial for maintaining high standards and adapting to evolving consumer preferences.`,
        subtitles: [
            `Refining Menu Offerings`,
            `Enhancing Service Efficiency`,
            `Understanding Dietary Preferences`,
            `Maintaining Cleanliness and Hygiene`,
            `Building Customer Loyalty`
        ],
        subdesc: [
            `Gastronomy surveys collect detailed feedback on menu items, helping organizations understand which dishes are most popular and which may need adjustment. By analyzing responses, businesses can optimize their menu to better match customer preferences, introducing new dishes that align with current trends or improving those that receive less favorable reviews. This process ensures that the menu remains appealing and relevant to patrons.`,
            `Feedback on service aspects, such as wait times, staff attentiveness, and overall service quality, provides valuable insights into operational efficiency. Identifying service bottlenecks or areas where staff may need additional training helps organizations enhance the customer experience, streamline operations, and reduce wait times, leading to increased satisfaction and repeat visits.`,
            `Surveys offer insights into evolving dietary trends and preferences, such as plant-based diets, gluten-free options, or sustainable practices. Understanding these preferences allows organizations to adapt their offerings, cater to a broader audience, and stay competitive. Incorporating popular dietary options into the menu can attract new customers and meet the needs of existing ones.`,
            `Feedback on the cleanliness of dining areas, kitchen facilities, and restrooms is crucial for maintaining high hygiene standards. Identifying areas where cleanliness may be lacking helps ensure compliance with health regulations and enhances the overall dining experience. Consistent attention to hygiene not only prevents negative reviews but also builds customer trust and loyalty.`,
            `Regularly collecting and acting on feedback demonstrates to customers that their opinions are valued. This practice fosters a sense of loyalty, as customers feel heard and appreciated. Addressing feedback and making improvements based on customer suggestions encourages repeat visits and positive word-of-mouth recommendations.`        
        ]
    },
    {
        title: 'Post Stay',
        description: `Post stay surveys allow organizations to gather comprehensive feedback after the experience has concluded. This type of survey covers all aspects, from the overall experience to specific service interactions, providing valuable insights into satisfaction.`,
        subtitles: [
            `Identifying Satisfaction Drivers`,
            `Uncovering Pain Points`,
            `Facilitating Continuous Improvement`,
            `Personalizing Future Interactions`,
            `Strengthening Customer Retention`        
        ],
        subdesc: [
            `Post stay surveys help organizations understand which factors contribute most to overall satisfaction. By analyzing feedback on various aspects such as product quality, service, and overall experience, businesses can pinpoint what drives positive experiences and prioritize improvements in these areas.`,
            `These surveys reveal any negative aspects of the experience, such as issues with service, product performance, or facility conditions. Addressing these pain points allows organizations to make necessary adjustments, resolve issues before they escalate, and enhance the overall experience for future customers.`,
            `Consistently gathering post stay feedback creates a cycle of improvement. By regularly reviewing and acting on survey results, organizations can make incremental changes that lead to better customer experiences and ongoing satisfaction. This proactive approach helps businesses adapt to evolving customer needs and preferences.`,
            `Data collected from post stay surveys can be used to tailor future interactions with customers. For example, businesses can use feedback to offer personalized recommendations, special offers, or targeted communications that reflect the customer’s previous preferences, enhancing their overall experience and increasing engagement.`,
            `Addressing concerns and feedback promptly helps build stronger relationships with customers. By demonstrating responsiveness and a commitment to improving based on feedback, organizations can boost customer retention rates and encourage long-term loyalty, leading to increased repeat business and positive referrals.`
        ]
    },
    {
        title: 'Property Survey',
        description: `Property surveys gather feedback on the condition, upkeep, and functionality of a venue’s physical space. Whether it's accommodations, common areas, or amenities, this survey focuses on the quality of the environment.`,
        subtitles: [
            `Identifying Maintenance Needs`,
            `Enhancing Comfort`,
            `Planning Upgrades`,
            `Ensuring Safety and Security`,
            `Improving Accessibility`,
            `Optimizing Resources`,
        ],
        subdesc: [
            `Property surveys are crucial for pinpointing areas that require maintenance or repair. By identifying issues such as wear and tear, malfunctioning equipment, or cleanliness problems, organizations can address these needs promptly. Regularly assessing maintenance requirements helps prevent minor issues from escalating into major problems, ensuring that the property remains functional and safe for all users.`,
            `Feedback from property surveys provides valuable insights into how comfortable the environment is for its occupants. This involves assessing factors such as temperature control, noise levels, air quality, and the ergonomic design of furniture and workspaces. Addressing comfort-related issues—like improving climate control systems, soundproofing areas, or upgrading seating—can significantly enhance the user experience. Ensuring a comfortable environment is essential for maintaining satisfaction and productivity, whether in an office, retail space, or public venue.`,
            `Survey data helps organizations make informed decisions about necessary upgrades or renovations. By analyzing feedback on the current condition and functionality of various property features, organizations can prioritize which areas require improvement. This might include modernizing outdated facilities, expanding amenities, or enhancing the overall aesthetic appeal. Strategic planning based on survey results ensures that investments are directed towards areas that will have the most significant positive impact on user experience and operational efficiency.`,
            `Evaluating safety and security measures is a key aspect of property surveys. Feedback helps identify potential hazards or areas where security could be improved, such as inadequate lighting, poorly marked emergency exits, or insufficient surveillance. Addressing these concerns is vital for creating a secure environment and protecting users. Ensuring that safety and security protocols are up-to-date and effective helps in maintaining trust and compliance with relevant regulations.`,
            `Surveys highlight how accessible the property is for all users, including those with disabilities. This includes assessing the availability and condition of ramps, elevators, signage, and accessible facilities. Feedback helps identify areas where accessibility can be improved, ensuring that the property meets regulatory standards and is welcoming to everyone. Enhancing accessibility not only fulfills legal requirements but also demonstrates a commitment to inclusivity and user-centric design.`,
            `Insights from property surveys assist in making informed decisions about resource allocation. By understanding which areas are most frequently used or which aspects require more attention, organizations can allocate their resources more effectively. This includes budgeting for maintenance, repairs, and upgrades in a way that maximizes impact and efficiency. Effective resource management helps ensure that investments are made in areas that will most benefit the property and its users.`,
        ]
    },
    {
        title: 'Facility',
        description: `Facility surveys focus on evaluating the functionality, cleanliness, and maintenance of the organization’s physical infrastructure. These surveys provide feedback on how well the venue or site is maintained and how accessible it is for individuals using it.`,
        subtitles: [
            `Assessing Cleanliness and Upkeep`,
            `Evaluating Accessibility`,
            `Safety and Security Feedback`,
            `Facility Improvements`,
            `Optimizing Operations`
        ],
        subdesc: [
            `Facility surveys evaluate the cleanliness and overall maintenance of various physical spaces within an organization. This involves checking the condition of surfaces, floors, restrooms, and common areas to ensure they meet cleanliness standards. Regular feedback helps identify areas where cleaning protocols may need to be improved or where maintenance has been neglected. By addressing cleanliness and upkeep issues promptly, organizations can provide a more inviting and hygienic environment for users, which contributes to a positive overall experience and promotes a professional image.`,
            `Surveys assess how accessible a facility is for all individuals, including those with disabilities. This includes evaluating the adequacy of ramps, elevators, signage, and the usability of facilities. Feedback from these surveys helps ensure that all users can navigate the space effectively and comfortably, addressing any barriers that might hinder access. Improving accessibility is crucial not only for compliance with legal requirements but also for demonstrating an organization's commitment to inclusivity and user-centric design.`,
            `Gathering feedback on safety and security measures is essential for maintaining a secure environment. This includes evaluating aspects such as lighting, emergency exits, surveillance systems, and overall security protocols. Users' perceptions of safety and security are critical for building trust and ensuring a positive experience. By addressing concerns related to safety, organizations can prevent incidents, enhance user confidence, and comply with safety regulations, ensuring a secure environment for all.`,
            `Survey results help identify areas in need of renovation or enhancement. Feedback might highlight issues with restrooms, entrances, parking areas, or other critical infrastructure. By understanding which aspects of the facility require attention, organizations can prioritize improvements that will have the most significant impact on user satisfaction. This targeted approach to facility upgrades ensures that resources are invested wisely and that improvements align with user needs and expectations.`,
            `Data from facility surveys provide insights into which facilities or services are most frequently used and which aspects might need adjustment. This information helps in optimizing operational efficiency by aligning resources and management practices with actual user needs. For example, understanding peak usage times or popular areas allows organizations to better allocate staff, manage maintenance schedules, and improve overall operational effectiveness, leading to a smoother and more efficient operation.`
        ]
    },
    {
        title: 'Other',
        description: `The “Other” survey category allows for the collection of feedback on various aspects of the overall experience or operation that may not fall under a specific category. This is ideal for obtaining insights on miscellaneous services or unique offerings.`,
        subtitles: [
            `Customized Feedback`,
            `Exploring New Area`,
            `Innovating Processes`,
            `Enhancing Satisfaction Holistically`,
        ],
        subdesc: [
            `The "Other" survey category offers the flexibility to gather feedback on aspects of the operation that don’t fall into standard categories. This might include unique services, specialized programs, or innovative features that are specific to the organization. By allowing for customized feedback, organizations can obtain detailed insights into areas that are critical but not covered by traditional survey types. This tailored approach helps in addressing specific user needs or interests, ensuring that all relevant aspects of the experience are evaluated.`,
            `If an organization is introducing new services, programs, or products, "Other" surveys are ideal for gauging initial reactions and assessing the viability of these initiatives. Feedback can provide valuable information on user preferences, potential improvements, and overall acceptance of new offerings. This helps organizations make informed decisions about whether to continue, modify, or discontinue these new elements based on user input.`,
            `Data collected through "Other" surveys can guide improvements in less conventional areas, such as new technologies, experimental projects, or unique amenities. Feedback helps in understanding how these innovations are received and what adjustments might be needed. By incorporating user input into the development and refinement of new processes, organizations can better align their offerings with user expectations and drive successful innovation.`,
            `The "Other" survey category ensures that all aspects of the user experience are covered, even those that don’t fit neatly into predefined categories. This comprehensive approach allows organizations to capture feedback on a wide range of elements, providing a complete view of the overall experience. By addressing feedback from all relevant areas, organizations can enhance satisfaction in a holistic manner, ensuring that every aspect of the user interaction is considered and improved as needed.`,
        ]
    }
]


const AboutSurveyTypes = () => {
    const [tabIndex, setTabIndex] = useState<number>(0);

    return (
        <Layout
			pageTitle={
				<div className="items-center min-h-10">
					<p>About survey analytics types</p>
                    <p className='text-gray-800 text-base font-light'>We offer a variety of surveys to gather your feedback on different aspects of your visit. Below, you can read more about each survey type and how they help us improve your experience.</p>
				</div>
			}
		>
            <div>
                <div className='m-auto max-w-[1000px] md:flex flex-col gap-10 relative min-h-screen'>
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                        <TabList className={'border-b-2 border-t-2 mb-4'}>
                            {
                                info.map((value, index) => (
                                    <Tab key={index} className={`inline-block cursor-pointer font-light hover:bg-gray-200 outline-none rounded-md ${tabIndex == index ? 'font-semibold border-b-4 border-b-blue-700 rounded-b-none' : ''} p-4`}>{value.title}</Tab>
                                ))
                            }
                        </TabList>

                        {
                            info.map((value, index) => (
                                <TabPanel key={index}>
                                    <p className='pb-4'>
                                        {value.description}
                                    </p>
                                    {                                      
                                        value.subtitles ? <div>
                                            {value.subtitles?.map((val, index) => (
                                                <div key={index} className="content text-gray-800 text-base flex flex-col gap-4">
                                                    <div className='w-full border-b-2 flex'>   
                                                        <p className="font-bold mt-4 pr-4">{val}</p>
                                                    </div>
                                                    <p className='font-light mb-4'>{value.subdesc.at(index)}</p>
                                                </div>
                                        ))}
                                        </div> : <></>
                                    }
                                </TabPanel>
                            ))
                        }
                        
                    </Tabs>
                </div>

            </div>
        </Layout>
    )
}

export default AboutSurveyTypes;
