import React from 'react';
import { ErrorMessage, FieldArray, FieldArrayRenderProps, FieldHelperProps } from 'formik';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { Button, FormikCheckbox } from 'components/common';
import { IOption } from 'components/form-elements';
import {
	FieldCheckbox,
	FieldInput,
	FieldSelect
} from 'components/formik-elements';
import { SurveySkipLogic } from 'components/survey/SurveySkipLogic';

import { capitalize } from 'utils';
import {
	useAlertDispatchContext,
	useLanguageContext,
	useSurveyCollapseContext,
	useSurveyCollapseDispatchContext
} from 'context';
import { CustomFileUpload } from 'components/form-elements/CustomFileUpload';
import { postFile } from 'services/files';
import { alertShowAction, expandQuestion, toggleQuestion } from 'reducers';
import { IQuestion, ISurveyUI } from 'services/surveys/interfaces';
import {
	getAllQuestions,
	createUniqueChoiceIndex,
	isLastInGroup,
	orderUp,
	orderDown,
	insertQuestionAtIndex,
	createInitialChoices,
	resetRangeValues,
	appearanceDescriptions,
	answerTypes,
	stringAnswerTypes,
	integerAnswerTypes,
	geopointAnswerType,
	imageAnswerType,
	dateAnswerType,
	getAppearanceOptions,
	resetAppearanceValue,
	countryAnswerType,
	resetAnalyticsValue,
	resetCheckboxValue,
	resetPropertyOptions,
	redirectConditions
} from 'components/survey/helpers';
import { EditEveryLanguageModal } from '../EditEveryLanguageModal';

import { ReactComponent as SvgClose } from 'assets/icons/close.svg';
import { ReactComponent as SvgDropdown } from 'assets/icons/chevron.svg';
import { ReactComponent as SvgDelete } from 'assets/icons/trash.svg';
import { ReactComponent as SvgPlus } from 'assets/icons/plus.svg';
import { ReactComponent as SvgEdit } from 'assets/icons/edit.svg';
import { AppearancePreview } from '../AppearancePreview';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import classNames from 'classnames';

interface ISurveyQuestionProps {
	groupName: string;
	indexInGroup: number;
	questionIndex: number;
	arrayHelpers: FieldArrayRenderProps;
	formValues: ISurveyUI;
	errors: any;
	question: IQuestion;
	properties : IOption[];
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>;
}

export const SurveyQuestion: React.FC<ISurveyQuestionProps> = ({
	groupName,
	indexInGroup,
	questionIndex,
	arrayHelpers,
	formValues,
	errors,
	question,
	properties,
	getFieldHelpers
}) => {
	const [animationParent] = useAutoAnimate({
		duration: 500
	});


	const { label, type, choices, hint, question_id } = question;

	const { currentLanguage, defaultLanguage, availableLanguages } =
		useLanguageContext();

	const alertDispatch = useAlertDispatchContext();
	const surveyCollapseDispatch = useSurveyCollapseDispatchContext();
	const { questions: collapsedQuestion } = useSurveyCollapseContext();

	const [tabIndex, setTabIndex] = React.useState<number>(0);
	React.useEffect(() => {
		if (
			getAllQuestions(formValues).filter(
				q => q.label?.[currentLanguage] && q.name !== question.name
			).length === 0
		) {
			setTabIndex(0);
		}
	}, [currentLanguage]);

	const hasQuestions = React.useMemo(() => {
		return (
			getAllQuestions(formValues).filter(
				q => q.label?.[currentLanguage] && q.name !== question.name
			).length > 0
		);
	}, [formValues, currentLanguage]);

	const isCollapsed = React.useMemo(
		() => collapsedQuestion.some(q => q === question.name),
		[collapsedQuestion, question]
	);

	const hasRedirectQuestions = React.useMemo(() => {
		return (
			getAllQuestions(formValues).filter(
				q => q.is_redirect_question
			).length > 0
		);
	}, [formValues, currentLanguage]);

	const limitAnswerTypes = (
		type: string,
		question_id?: number | null,
	): IOption[] => {
		const hasQuestionId: boolean = typeof question_id === 'number';
		const isStringType: boolean =
			/^(text|select_one|select_multiple|emoji_rating|email|sex|nps|rating|property|matrix_rating|select_one_img|select_multiple_img)$/.test(
				type
			);
		const isIntegerType: boolean = /^(integer|decimal|range|age)$/.test(type);
		const isGeopointType: boolean = /^geopoint$/.test(type);
		const isImageType: boolean = /^image$/.test(type);
		const isCountryType: boolean = /^country$/.test(type);


		if (!hasQuestionId) {
			return answerTypes;
		} else {
			if (isStringType) {
				return stringAnswerTypes;
			} else if (isIntegerType) {
				return integerAnswerTypes;
			} else if (isGeopointType) {
				return geopointAnswerType;
			} else if (isImageType) {
				return imageAnswerType;
			} else if (isCountryType) {
				return countryAnswerType;
			} else {
				return dateAnswerType;
			}
		}
	};

	//handle editEveryLanguage
	const [editEveryLanguageModal, setEditEveryLanguageModal] = React.useState<
		Record<string, any>
	>({
		isOpen: false,
		item: '',
		itemType: '',
		choiceIndex: ''
	});

	const actionButtons = (survey?: ISurveyUI) => {
		return (
			<div className={`flex items-center justify-between ml-4 mt-9 h-min`}>
				<button
					type="button"
					onClick={() => surveyCollapseDispatch(toggleQuestion(question.name))}
					className="flex items-center justify-center mr-2 w-5 h-5 hover:text-blue-700"
				>
					<SvgDropdown
						className={`${isCollapsed ? '' : 'rotate-90'} transition-transform`}
					/>
				</button>

				{availableLanguages.length > 1 && (
					<button
						type="button"
						onClick={() =>
							setEditEveryLanguageModal({
								isOpen: true,
								item: question,
								itemType: 'question'
							})
						}
						className={`flex items-center justify-center mr-2 w-5 h-5 hover:text-blue-700`}
						/* disabled={
							survey?.submissions_count &&
							survey?.submissions_count !== 0
								? true
								: false
						} */
					>
						<SvgEdit />
					</button>
				)}

				<button
					type="button"
					onClick={() => onDelete(indexInGroup, question.name)}
					className={`flex items-center justify-center w-5 h-5 hover:text-red-500 ${
						survey?.submissions_count &&
						survey?.submissions_count !== 0 &&
						question.question_id !== undefined
							? 'pointer-events-none text-gray-300'
							: ''
					}`}
					disabled={
						survey?.submissions_count &&
						survey?.submissions_count !== 0 &&
						question.question_id !== undefined
							? true
							: false
					}
				>
					<SvgDelete />
				</button>
			</div>
		);
	};

	const onDelete = (index: number, name: string) => {
		alertDispatch(
			alertShowAction({
				header: `Delete question?`,
				message: `Are you sure you want to delete this question?`,

				buttons: [
					{
						text: `Close`,
						type: 'light'
					},
					{
						text: `Confirm`,
						handler: () => {
							arrayHelpers.remove(index);
							surveyCollapseDispatch(expandQuestion(name));
						}
					}
				]
			})
		);
	};

	const handleAnswerTypeChange = (
		e: IOption,
		helper: FieldArrayRenderProps
	) => {
		createInitialChoices(e, helper, groupName, indexInGroup,properties, availableLanguages);
		resetRangeValues(helper, groupName, indexInGroup);
		resetAppearanceValue(e, helper, groupName, indexInGroup);
		resetAnalyticsValue(helper, groupName, indexInGroup);
		resetCheckboxValue(e, helper, groupName, indexInGroup);
	};

	if (question.type === 'end_group') {
		return <></>;
	}

	const disableAnalytics =
		getAllQuestions(arrayHelpers.form.values)
			.filter(item => item.name !== question.name)
			.filter(item =>
				['age', 'country', 'sex', 'select_one'].includes(item.type)
			)
			.filter(
				item => item.analytics_type === true && item.type === question.type
			).length === 1;

	return (
		<>
			<div
				ref={animationParent as any}
				className={`flex flex-row-reverse px-4 py-6 border rounded-lg ${
					errors ? 'border-red-300 bg-red-50' : 'border-gray-300 bg-gray-50'
				}`}
			>
				<div className={`flex-1`}>
					<div className="flex justify-between">
						<FieldInput
							className="flex-1"
							name={`${groupName}.${indexInGroup}.label.${currentLanguage}`}
							id={`${groupName}.${indexInGroup}.label.${currentLanguage}`}
							label={`Question #${questionIndex + 1}`}
							value={label?.[currentLanguage]}
							type="text"
							error={
								arrayHelpers.form.submitCount && errors
									? errors.label?.[currentLanguage]
									: undefined
							}
							onFocus={() =>
								surveyCollapseDispatch(expandQuestion(question.name))
							}
							placeholder={classNames({
								[`${question.label?.[defaultLanguage]}`]:
									question.label?.[defaultLanguage],
								'On a scale from zero to 10, how likely is it that you would recommend us to a friend or colleague?':
									question.type === 'nps' && !question.label?.[defaultLanguage],
								'':
									!question.label?.[defaultLanguage] && question.type !== 'nps'
							})}
							/* disabled={
								formValues.submissions_count &&
								formValues?.submissions_count !== 0
									? true
									: false
							} */
						/>

						{actionButtons(formValues)}
					</div>
					{!isCollapsed && (
						<div className="mt-6 ">
							<Tabs
								selectedIndex={tabIndex}
								onSelect={index => setTabIndex(index)}
							>
								<TabList>
									<Tab>Options</Tab>
									<Tab disabled={!hasQuestions}>Skip logic</Tab>
								</TabList>

								<TabPanel>
									<div className="space-y-6">
										<FieldInput
											name={`${groupName}.${indexInGroup}.hint.${currentLanguage}`}
											id={`${groupName}.${indexInGroup}.hint.${currentLanguage}`}
											label="Hint"
											value={hint?.[currentLanguage]}
											placeholder={
												(hint?.[defaultLanguage] && hint?.[defaultLanguage]) ||
												''
											}
										/>
										<FieldCheckbox
											name={`${groupName}.${indexInGroup}.required`}
											id={`${groupName}.${indexInGroup}.required`}
											label="Is this a required question?"
											type="checkbox"
											variant="toggler"
											tabIndex={0}
										/>

											
										
										<FieldArray
											name={`${groupName}.${indexInGroup}.choices`}
											render={helper => {
												//get multiple indexOf helper function
												const indexesOf = (
													array: string[],
													item: string
												): number[] =>
													array.reduce(
														(accumulator: number[], value, index) => (
															value === item && accumulator.push(index),
															accumulator
														),
														[]
													);

												//get all general questions which contain this question in skip logic
												const inGeneral: IQuestion[] =
													formValues.general_questions.filter(
														general_question =>
															general_question.skip_logic.find(
																rule => rule.question === question.name
															)
													);

												//get all section questions which contain this question in skip logic
												const inSections: IQuestion[] = formValues.sections
													.map(section => section.questions)
													.flat()
													.filter(section_question =>
														section_question.skip_logic.find(
															rule => rule.question === question.name
														)
													);

												//merge general and section questions in one array
												const questionsThatContainThisQuestionInSkipLogic: IQuestion[] =
													[...inGeneral, ...inSections];

												//get arrays from which we will remove all occurences of this question
												const arraysToRemoveFrom: string[][] =
													questionsThatContainThisQuestionInSkipLogic.map(
														item => item.skip_logic.map(item => item.question)
													);

												//find indexes of this question in arraysToRemoveFrom
												const indexesToBeRemoved: number[][] =
													arraysToRemoveFrom.map(item => {
														return indexesOf(item, question.name);
													});

												return (
													<>
													
														<FieldSelect
															name={`${groupName}.${indexInGroup}.type`}
															id={`${groupName}.${indexInGroup}.type`}
															label="Question type"
															placeholder="Select question type"
															onChange={(e: any) => {
																handleAnswerTypeChange(e, helper);

																//for each other question that contains this question in its skip logic do this
																questionsThatContainThisQuestionInSkipLogic.map(
																	(item: IQuestion, index: number) => {
																		//get indexes marked for removal in current question's (index) skip logic
																		const toRemove: number[] =
																			indexesToBeRemoved[index];

																		//remove every index marked for removal in this item, start from end not to mess indexes
																		for (
																			let i = toRemove.length - 1;
																			i >= 0;
																			i--
																		)
																			item.skip_logic.splice(toRemove?.[i], 1);
																	}
																);
															}}
															value={{
																value: type,
																display: capitalize(type).replace(/_/g, ' ')
															}}
															options={limitAnswerTypes(type, question_id)}
															//featuredChoices are highlighted or displayed separately
															featuredChoices={[
																'text',
																'emoji_rating',
																'rating',
																'nps',
																'country',
																'sex',
																'age',
																'select_one',
																'matrix_rating'
															]}
															error={
																errors?.general_questions?.type ? 'greška'
																	: undefined
															}
														/>
														<ErrorMessage className="text-xs text-red-500 mt-1 max-w-full w-full errorElement" name={`${groupName}.${indexInGroup}.type`} component="div" />
														{(type==='rating' && (!hasRedirectQuestions || question.is_redirect_question)) &&
															<FieldCheckbox
																name={`${groupName}.${indexInGroup}.is_redirect_question`}
																id={`${groupName}.${indexInGroup}.is_redirect_question`}
																label="Is this a conditional redirect question?"
																type="checkbox"
																variant="toggler"
															/>
														}
														{(type==='rating') && question.is_redirect_question &&
															<FieldSelect
															name={`${groupName}.${indexInGroup}.redirect_condition`}
															id={`${groupName}.${indexInGroup}.redirect_condition`}
															label="Greater than or equal to*"
															required
															options={redirectConditions}
															/>
													}
													{(type==='rating') && question.is_redirect_question &&
															<FieldInput
															name={`${groupName}.${indexInGroup}.redirect_link`}
															id={`${groupName}.${indexInGroup}.redirect_link`}
															label="Redirect link"
															placeholder='https://example.com'
															/>
														}
														{[
															'text',
															'date',
															'email',
															'age',
															'sex',
														].includes(type) &&
															<FieldCheckbox
																name={`${groupName}.${indexInGroup}.is_sensitive`}
																id={`${groupName}.${indexInGroup}.is_sensitive`}
																label="Is this a GDPR sensitive question?"
																type="checkbox"
																variant="toggler"
												
															/>
														}
														{['select_one', 'select_multiple', 'sex','matrix_rating'].includes(
															type
														) && (
															<>
																{choices?.map((choice, choiceIndex) => (
																	<div className="flex gap-4" key={choiceIndex}>
																		<div className="flex w-full flex-row-reverse gap-4">
																			<FieldInput
																				className="w-full"
																				name={`${groupName}.${indexInGroup}.choices.${choiceIndex}.label.${currentLanguage}`}
																				id={`${groupName}.${indexInGroup}.choices.${choiceIndex}.label.${currentLanguage}`}
																				label={`${type=='matrix_rating' ? 'Row' :'Choice'} ${choiceIndex + 1}`}
																				value={choice.label?.[currentLanguage]}
																				error={
																					arrayHelpers.form.submitCount &&
																					errors?.choices?.[choiceIndex]
																						? errors?.choices?.[choiceIndex]
																								?.label?.[currentLanguage]
																						: undefined
																				}
																				placeholder={
																					(choice.label?.[defaultLanguage] &&
																						choice.label?.[defaultLanguage]) ||
																					''
																				}
																			/>
																			<div className="flex flex-col h-min mt-8">
																				<button
																					tabIndex={choiceIndex === 0 ? -1 : 0}
																					type="button"
																					className={`flex items-center justify-center w-3 hover:text-blue-700 ${
																						choiceIndex === 0
																							? 'text-gray-300 hover:text-gray-300 cursor-auto'
																							: 'hover:text-blue-700'
																					}`}
																					onClick={() =>
																						orderUp(
																							helper,
																							getFieldHelpers,
																							choiceIndex,
																							question,
																							groupName,
																							formValues
																						)
																					}
																				>
																					<SvgDropdown className="-rotate-90" />
																				</button>
																				<button
																					tabIndex={
																						choiceIndex === choices.length - 1
																							? -1
																							: 0
																					}
																					type="button"
																					className={`flex items-center justify-center w-3 hover:text-blue-700 ${
																						choiceIndex + 1 === choices.length
																							? 'text-gray-300 hover:text-gray-300 cursor-auto'
																							: 'hover:text-blue-700'
																					}`}
																					onClick={() =>
																						orderDown(
																							helper,
																							getFieldHelpers,
																							choiceIndex,
																							question,
																							groupName,
																							formValues
																						)
																					}
																				>
																					<SvgDropdown className="rotate-90" />
																				</button>
																			</div>
																		</div>

																		{availableLanguages.length > 1 && (
																			<button
																				type="button"
																				onClick={() =>
																					setEditEveryLanguageModal({
																						isOpen: true,
																						item: choice,
																						itemType: 'choice',
																						choiceIndex: choiceIndex
																					})
																				}
																				className="flex items-center justify-center w-5 h-5 hover:text-blue-700 mt-9 pb-1"
																			>
																				<SvgEdit />
																			</button>
																		)}

																		{choices.length > 2 && (
																			<button
																				type="button"
																				onClick={() =>
																					helper.remove(choiceIndex)
																				}
																				className="flex-none w-8 h-8 p-2.5 mt-7 text-white text-lg flex justify-center items-center bg-blue-700 hover:bg-blue-600 rounded-full"
																			>
																				<SvgClose />
																			</button>
																		)}
																	</div>
																))}
																<Button
																	type="button"
																	text={`Add ${type=='matrix_rating' ? 'row' :'option'}`}
																	size="small"
																	variant="light"
																	onClick={() => {
																		helper.push({
																			name: `choice_${createUniqueChoiceIndex(
																				question
																			)}`,
																			label: ''
																		});
																	}}
																/>
															</>
														)}
														{['select_one_img', 'select_multiple_img'].includes(
															type
														) && (
															<>
																{choices?.map((choice, choiceIndex) => (
																	<div className="flex gap-4" key={choiceIndex}>
																		<div className="flex w-full flex-row-reverse items-center gap-4">
																			<div
																				className={`flex ${
																					choice.choice_image === '' ? 'items-center' : 'items-start'
																				}`}
																			>
																				{choice.choice_image !== '' && choice.choice_image !== null && choice.choice_image &&(
																					<img
																						src={choice.choice_image}
																						alt="choice image"
																						className="max-w-xs object-contain mr-4"
																					/>
																				)}

																				<CustomFileUpload
																					className=''
																					name="choice_image"
																					id="choice_image"
																					onUpload={(e: any) => 
																						postFile(e.target.files).then(res => {
																							getFieldHelpers(`${groupName}.${indexInGroup}.choices.${choiceIndex}.choice_image`).setValue(res.file_url);
																						})
																					}
																					onClear={() => 
																						getFieldHelpers(`${groupName}.${indexInGroup}.choices.${choiceIndex}.choice_image`).setValue(null)
																					}
																					hasValue={choice.choice_image}
																				/>
																			</div>																
																			<FieldInput
																				className="w-full"
																				name={`${groupName}.${indexInGroup}.choices.${choiceIndex}.label.${currentLanguage}`}
																				id={`${groupName}.${indexInGroup}.choices.${choiceIndex}.label.${currentLanguage}`}
																				label={`Choice ${choiceIndex + 1}`}
																				value={choice.label?.[currentLanguage]}
																				error={
																					arrayHelpers.form.submitCount &&
																					errors?.choices?.[choiceIndex]
																						? errors?.choices?.[choiceIndex]
																								?.label?.[currentLanguage]
																						: undefined
																				}
																				placeholder={
																					(choice.label?.[defaultLanguage] &&
																						choice.label?.[defaultLanguage]) ||
																					''
																				}
																			/>
																			<div className="flex flex-col h-min mt-8">
																				<button
																					tabIndex={choiceIndex === 0 ? -1 : 0}
																					type="button"
																					className={`flex items-center justify-center w-3 hover:text-blue-700 ${
																						choiceIndex === 0
																							? 'text-gray-300 hover:text-gray-300 cursor-auto'
																							: 'hover:text-blue-700'
																					}`}
																					onClick={() =>
																						orderUp(
																							helper,
																							getFieldHelpers,
																							choiceIndex,
																							question,
																							groupName,
																							formValues
																						)
																					}
																				>
																					<SvgDropdown className="-rotate-90" />
																				</button>
																				<button
																					tabIndex={
																						choiceIndex === choices.length - 1
																							? -1
																							: 0
																					}
																					type="button"
																					className={`flex items-center justify-center w-3 hover:text-blue-700 ${
																						choiceIndex + 1 === choices.length
																							? 'text-gray-300 hover:text-gray-300 cursor-auto'
																							: 'hover:text-blue-700'
																					}`}
																					onClick={() =>
																						orderDown(
																							helper,
																							getFieldHelpers,
																							choiceIndex,
																							question,
																							groupName,
																							formValues
																						)
																					}
																				>
																					<SvgDropdown className="rotate-90" />
																				</button>
																			</div>
																		</div>

																		{availableLanguages.length > 1 && (
																			<button
																				type="button"
																				onClick={() =>
																					setEditEveryLanguageModal({
																						isOpen: true,
																						item: choice,
																						itemType: 'choice',
																						choiceIndex: choiceIndex
																					})
																				}
																				className="flex items-center justify-center w-5 h-5 hover:text-blue-700 mt-9 pb-1"
																			>
																				<SvgEdit />
																			</button>
																		)}

																		{choices.length > 2 && (
																			<button
																				type="button"
																				onClick={() =>
																					helper.remove(choiceIndex)
																				}
																				className="flex-none w-8 h-8 p-2.5 mt-7 text-white text-lg flex justify-center items-center bg-blue-700 hover:bg-blue-600 rounded-full"
																			>
																				<SvgClose />
																			</button>
																		)}
																	</div>
																))}
																<Button
																	type="button"
																	text={`Add option`}
																	size="small"
																	variant="light"
																	onClick={() => {
																		helper.push({
																			name: `choice_${createUniqueChoiceIndex(
																				question
																			)}`,
																			label: ''
																		});
																	}}
																/>
															</>
														)}
														{'property' === type && (
															<>
																{choices?.map((choice, choiceIndex) => (
																	<div className="flex gap-4" key={choiceIndex}>
																		<div className="flex w-full flex-row-reverse gap-4">
																			<FieldInput
																				className="w-full"
																				readOnly={true}
																				name={`${groupName}.${indexInGroup}.choices.${choiceIndex}.label.${currentLanguage}`}
																				id={`${groupName}.${indexInGroup}.choices.${choiceIndex}.label.${currentLanguage}`}
																				label={`Choice ${choiceIndex + 1}`}
																				value={choice.label?.[currentLanguage]}
																				error={
																					arrayHelpers.form.submitCount &&
																					errors?.choices?.[choiceIndex]
																						? errors?.choices?.[choiceIndex]
																								?.label?.[currentLanguage]
																						: undefined
																				}
																				placeholder={
																					(choice.label?.[defaultLanguage] &&
																						choice.label?.[defaultLanguage]) ||
																					''
																				}
																			/>
																			<div className="flex flex-col h-min mt-8">
																				<button
																					tabIndex={choiceIndex === 0 ? -1 : 0}
																					type="button"
																					className={`flex items-center justify-center w-3 hover:text-blue-700 ${
																						choiceIndex === 0
																							? 'text-gray-300 hover:text-gray-300 cursor-auto'
																							: 'hover:text-blue-700'
																					}`}
																					onClick={() =>
																						orderUp(
																							helper,
																							getFieldHelpers,
																							choiceIndex,
																							question,
																							groupName,
																							formValues
																						)
																					}
																				>
																					<SvgDropdown className="-rotate-90" />
																				</button>
																				<button
																					tabIndex={
																						choiceIndex === choices.length - 1
																							? -1
																							: 0
																					}
																					type="button"
																					className={`flex items-center justify-center w-3 hover:text-blue-700 ${
																						choiceIndex + 1 === choices.length
																							? 'text-gray-300 hover:text-gray-300 cursor-auto'
																							: 'hover:text-blue-700'
																					}`}
																					onClick={() =>
																						orderDown(
																							helper,
																							getFieldHelpers,
																							choiceIndex,
																							question,
																							groupName,
																							formValues
																						)
																					}
																				>
																					<SvgDropdown className="rotate-90" />
																				</button>
																			</div>
																		</div>

																	
																		{choices.length > 2 && (
																			<button
																				type="button"
																				onClick={() =>
																					helper.remove(choiceIndex)
																				}
																				className="flex-none w-8 h-8 p-2.5 mt-7 text-white text-lg flex justify-center items-center bg-blue-700 hover:bg-blue-600 rounded-full"
																			>
																				<SvgClose />
																			</button>
																		)}
																	</div>
																))}
																<Button
																	type="button"
																	text="Reset options"
																	size="small"
																	variant="light"
																	onClick={() =>
																		resetPropertyOptions(
																			helper,groupName, indexInGroup,properties, availableLanguages
																		)
																	}
																/>
															</>
														)}
													</>
												);
											}}
										/>
										{type === 'range' && (
											<div className="flex -mx-2">
												<FieldInput
													className="w-1/3 px-2 flex-none"
													name={`${groupName}.${indexInGroup}.range_min`}
													id={`${groupName}.${indexInGroup}.range_min`}
													label={`Range min`}
													error={
														arrayHelpers.form.submitCount && errors?.range_min
															? errors?.range_min
															: undefined
													}
												/>
												<FieldInput
													className="w-1/3 px-2 flex-none"
													name={`${groupName}.${indexInGroup}.range_step`}
													id={`${groupName}.${indexInGroup}.range_step`}
													label={`Range step`}
													error={
														arrayHelpers.form.submitCount && errors?.range_step
															? errors?.range_step
															: undefined
													}
												/>
												<FieldInput
													className="w-1/3 px-2 flex-none"
													name={`${groupName}.${indexInGroup}.range_max`}
													id={`${groupName}.${indexInGroup}.range_max`}
													label={`Range max`}
													error={
														arrayHelpers.form.submitCount && errors?.range_max
															? errors?.range_max
															: undefined
													}
												/>
											</div>
										)}
										{[
											'text',
											'date',
											'select_one',
											'select_multiple',
											'image'
										].includes(type) && (
											<div>
												<FieldSelect
													name={`${groupName}.${indexInGroup}.appearance`}
													id={`${groupName}.${indexInGroup}.appearance`}
													label="Appearance"
													options={getAppearanceOptions(type)}
												/>

												<p className="mt-1 text-xs text-gray-400">
													{appearanceDescriptions[question.appearance]}
												</p>
											</div>
										)}

										<div>
											<AppearancePreview
												type={type}
												appearance={question.appearance}
											/>
										</div>

										<div>
											{[
												'text',
												'emoji_rating',
												'rating',
												'nps',
												'country',
												'sex',
												'age',
												'select_one',
												'matrix_rating'
											].includes(type) && (
												<FormikCheckbox
													name={`${groupName}.${indexInGroup}.analytics_type`}
													id={`${groupName}.${indexInGroup}.analytics_type`}
													label="Include in analytics"
													type="checkbox"
													variant="toggler"
													tabIndex={0}
													disabled={disableAnalytics}
												/>
											)}

											{disableAnalytics && (
												<p className="mt-1 text-xs text-gray-400">
													You can only include one
													<strong className="mx-1">
														"{capitalize(type).replace('_', ' ')}"
													</strong>
													type in analytics.
												</p>
											)}
										</div>
									</div>
								</TabPanel>

								<TabPanel>
									<p className="text-sm mb-5">
										This question will only be displayed if the following
										conditions apply
									</p>
									<SurveySkipLogic
										getFieldHelpers={getFieldHelpers}
										groupName={groupName}
										index={indexInGroup}
										survey={formValues}
										item={question}
									/>
								</TabPanel>
							</Tabs>
						</div>
					)}
				</div>

				<div className="flex flex-col h-min mr-4 w-4">
					<button
						tabIndex={questionIndex === 0 ? -1 : 0}
						type="button"
						className={`flex items-center justify-center w-3 ${
							questionIndex === 0 ||
							(indexInGroup === 0 &&
								question.question_id !== undefined &&
								formValues.survey_status === 'open_success')
								? 'text-gray-300 hover:text-gray-300 cursor-auto pointer-events-none'
								: 'hover:text-blue-700'
						}`}
						onClick={() =>
							orderUp(
								arrayHelpers,
								getFieldHelpers,
								indexInGroup,
								question,
								groupName,
								formValues
							)
						}
						disabled={
							indexInGroup === 0 &&
							question.question_id !== undefined &&
							formValues.survey_status === 'open_success'
						}
					>
						<SvgDropdown className="-rotate-90" />
					</button>

					<button
						tabIndex={
							questionIndex === getAllQuestions(formValues).length - 1 ? -1 : 0
						}
						type="button"
						className={`flex items-center justify-center w-3 ${
							questionIndex === getAllQuestions(formValues).length - 1 ||
							(isLastInGroup(question, formValues) &&
								question.question_id !== undefined &&
								formValues.survey_status === 'open_success')
								? 'text-gray-300 hover:text-gray-300 cursor-auto pointer-events-none'
								: 'hover:text-blue-700'
						}`}
						onClick={() =>
							orderDown(
								arrayHelpers,
								getFieldHelpers,
								indexInGroup,
								question,
								groupName,
								formValues
							)
						}
						disabled={
							isLastInGroup(question, formValues) &&
							question.question_id !== undefined &&
							formValues.survey_status === 'open_success'
						}
					>
						<SvgDropdown className="rotate-90" />
					</button>
				</div>
			</div>

			{!isLastInGroup(question, formValues) && (
				<div className="flex items-center group py-3 opacity-0 hover:opacity-100 delay-150 transition-all duration-150">
					<hr className="flex-1" />
					<button
						type="button"
						className="w-0 h-0 group-hover:w-8 group-hover:h-8 bg-blue-600 rounded-full flex items-center justify-center p-0 group-hover:p-2.5 transition-all duration-150 delay-150"
						onClick={() =>
							insertQuestionAtIndex(indexInGroup + 1, arrayHelpers, formValues)
						}
					>
						<SvgPlus className="text-white h-0 group-hover:h-8 delay-150 transition-all duration-150" />
					</button>
					<hr className="flex-1" />
				</div>
			)}
			<EditEveryLanguageModal
				isOpen={editEveryLanguageModal.isOpen}
				onRequestClose={() =>
					setEditEveryLanguageModal({
						isOpen: false,
						item: '',
						itemType: '',
						choiceIndex: ''
					})
				}
				item={editEveryLanguageModal.item}
				itemType={editEveryLanguageModal.itemType}
				groupName={groupName}
				indexInGroup={indexInGroup}
				questionIndex={questionIndex}
				choiceIndex={editEveryLanguageModal.choiceIndex}
			/>
		</>
	);
};
