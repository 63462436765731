import React from 'react';
import { useNavigate } from 'react-router-dom';

// library commented
// import { Layout } from 'components/shared';
// import { Button } from 'components/common/Button';

// import { ReactComponent as SvgPlus } from 'assets/icons/plus.svg';
// import { useToast } from 'hooks';
// import { useAlertDispatchContext } from 'context';
// import { useQueryClient } from 'react-query';
// import { ISurvey } from 'services/surveys/interfaces';
// import { alertShowAction } from 'reducers';
// import { deleteSurvey } from 'services/surveys';
// import { TemplateTable } from 'components/template/TemplateTable';
// import { SurveyCloneModal } from 'components/survey';
// import { useGetQuotaQuery } from 'services/subscriptions';
// import { useGetTemplatesQuery } from 'services/templates';
// import { Loader } from 'components/common';
// import { ReactComponent as SvgWarning } from 'assets/icons/warning.svg';
// import HelpInfo from 'components/common/helpInfo/HelpInfo';

const Templates = () => {
	const navigate = useNavigate();

	React.useEffect(() => {
		navigate('/')
	}, []);


	// library commented
	// const show = useToast();
	// const alertDispatch = useAlertDispatchContext();
	// const queryClient = useQueryClient();

	// const { data: quota, isLoading: isLoadingQuota } = useGetQuotaQuery();
	// const { data: templates, isLoading: isLoadingTemplates } =
	// 	useGetTemplatesQuery();

	// const [copyModalShown, setCopyModalShown] = React.useState(false);
	// const [surveyToCopy, setSurveyToCopy] = React.useState<ISurvey>();

	// const deleteHandler = (survey: ISurvey) => {
	// 	alertDispatch(
	// 		alertShowAction({
	// 			header: `Delete library?`,
	// 			message: `Are you sure you want to delete ${survey.survey_name}?`,

	// 			buttons: [
	// 				{
	// 					text: `Close`,
	// 					type: 'light'
	// 				},
	// 				{
	// 					text: `Delete`,
	// 					handler: () =>
	// 						deleteSurvey(String(survey.survey_id))
	// 							.then(() => {
	// 								queryClient.invalidateQueries('templates');
	// 								show({
	// 									type: 'success',
	// 									headerText: 'Deleted!',
	// 									message: 'Successfully deleted library'
	// 								});
	// 							})
	// 							.catch(() => {
	// 								show({
	// 									type: 'error',
	// 									headerText: 'Error!',
	// 									message: 'Something went wrong'
	// 								});
	// 							})
	// 				}
	// 			]
	// 		})
	// 	);
	// };

	// const editHandler = (survey: ISurvey) => {
	// 	navigate(`/templates/${survey.survey_id}`);
	// };

	// const copyHandler = (survey: ISurvey) => {
	// 	setCopyModalShown(true);
	// 	setSurveyToCopy(survey);
	// };

	// if (isLoadingQuota || isLoadingTemplates) {
	// 	return (
	// 		<Layout pageTitle="Library">
	// 			<div className="w-full h-[60vh] flex flex-col items-center justify-center">
	// 				<Loader />
	// 			</div>
	// 		</Layout>
	// 	);
	// }

	// if (!quota || !templates) {
	// 	return (
	// 		<Layout pageTitle="Library">
	// 			<div className="w-full h-[60vh] flex flex-col items-center justify-center">
	// 				<SvgWarning className="w-64 h-64 text-gray-200" />
	// 				<p className="text-xl">Something went wrong</p>
	// 			</div>
	// 		</Layout>
	// 	);
	// }

	// const disableNewTemplate = quota?.n_survey_templates >= templates.count;

	return (
		<>
		</>
		// library commented
		// <Layout
		// 	pageTitle="Library"
		// 	headerComponent={
		// 		<div className="flex items-center">
		// 			{disableNewTemplate && (
		// 				<p className="flex items-center text-xs text-orange-500 mr-8">
		// 					<SvgWarning className="w-4 h-4 mr-2" />

		// 					<span>You have reached the maximum number of libraries</span>
		// 				</p>
		// 			)}

		// 			<Button
		// 				text="New library"
		// 				size="small"
		// 				iconLeft={<SvgPlus />}
		// 				href="/templates/new-template"
		// 				disabled={disableNewTemplate}
		// 			/>
		// 		</div>
		// 	}
		// >
		// 	<HelpInfo 
		// 		message='Libraries are used for storing templates of your survey so that every time you are creating a new survey, you can import your existing templates'
		// 		icon={true}
		// 		hoverMessageFirstLine='Consider library as your <strong>templates library</strong>. While you are creating or editing your surveys, you can <strong>use</strong> any of your created libraries to <strong>speed up</strong> the process.'
		// 		hoverMessageSecondLine="We have created some demo libraries for you that you can use any time."
		// 	/>
		// 	<TemplateTable
		// 		disableDuplicate={disableNewTemplate}
		// 		onDelete={deleteHandler}
		// 		onEdit={editHandler}
		// 		onCopy={copyHandler}
		// 	/>

		// 	<SurveyCloneModal
		// 		modalTitle="New library name"
		// 		survey={surveyToCopy as ISurvey}
		// 		isOpen={copyModalShown}
		// 		onClose={() => setCopyModalShown(false)}
		// 		isTemplate
		// 	/>
		// </Layout>
	);
};

export default Templates;
