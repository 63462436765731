import { useStep } from 'context/stepContext';
import React, { useState } from 'react'
import { stepSetAction } from 'reducers/onboardingReducer';
import { Switch } from "@/components/ui/switch"

export default function OnboardingStepSevenSettings() {

    const [checked, setChecked] = useState<boolean>(false)
    
    const { dispatch } = useStep();

    function onContinue(){
        if(checked){
            dispatch(stepSetAction({ should_show_onboarding: false }));
        } else {
            dispatch(stepSetAction({ stepseven: true }));
        }
    }

    return (
        <div className='absolute inset-0 flex bg-black/50 z-50 justify-center items-center px-10'>
            <div className='max-w-sm p-4 rounded-lg flex flex-col bg-[#F5F5F5] gap-14 text-sm sm:text-base'>
                <div className='flex flex-col gap-4'>
                    <p>This is the <strong>settings page</strong> where you can edit your account information, change password, add tags, add properties, edit return url and so on.</p>
                    <span>You can check out our full documentation <a href='https://megatrendgrupa.atlassian.net/wiki/external/MTA2NDc4YTAwZTE3NDMwMWEwMWViYmJiOTRmNjk0NDE' target='_blank' className='underline text-blue-400 hover:text-blue-800'>here</a>.</span>
                    <p>Was this onboarding good? We would like to hear from you, plus you can see how surveys work. 😁</p>
                    <a href="https://hi.hospitalityinsights.eu/i/bbOcfd" target='_blank' className='underline text-blue-400 hover:text-blue-800'>https://hi.hospitalityinsights.eu/i/bbOcfd</a>
                </div>
                <div className='flex flex-col gap-4 justify-end items-end'>
                    <div className="flex items-end sm:items-center space-x-2 flex-col sm:flex-row">
                        <label htmlFor="off" className='text-sm order-1 sm:order-none text-right'>Turn off onboarding</label>
                        <Switch 
                            id="off" 
                            checked={checked}
                            onCheckedChange={(isChecked:boolean) => setChecked(isChecked)}
                        />
                    </div>
                    <div className='flex justify-end'>
                        <button className="bg-[#004E96] p-2 px-4 rounded-lg text-sm sm:text-base text-white ml-4 sm:ml-0" onClick={onContinue}>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
