import { useStep } from 'context/stepContext';
import React, { useState } from 'react'
import { stepSetAction } from 'reducers/onboardingReducer';
import { Switch } from "@/components/ui/switch"

export default function OnboardingIndex() {

    const [checked, setChecked] = useState<boolean>(false)
    
    const { dispatch } = useStep();

    function onContinue(){
        if(checked){
            dispatch(stepSetAction({ should_show_onboarding: false }));
        } else {
            dispatch(stepSetAction({ index: true }));
        }
    }
  
    return (
        <div className='absolute inset-0 flex bg-black/50 z-50 justify-center items-center px-10'>
            <div className='bg-[#f5f5f5] max-w-5xl p-6 rounded-lg flex flex-col gap-10'>
                <h2 className='text-base sm:text-lg font-semibold'>Let's start with simple onboarding</h2>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-8 lg:gap-4'>
                    <div className='p-3 bg-[#e8e8e8] rounded-lg'>
                        <h3 className='text-base sm:text-lg text-center mb-2'>1. Create survey</h3>
                        <p className='text-left text-sm sm:text-base'>Create customized surveys tailored to your specific needs, ensuring you gather valuable insights from your guests.</p>
                    </div>
                    <div className='p-3 bg-[#e8e8e8] rounded-lg'>
                        <h3 className='text-base sm:text-lg text-center mb-2'>2. Publish survey</h3>
                        <p className='text-left text-sm sm:text-base'>Publish your survey once ready to distribute, as only published surveys, marked as 'Open', are accessible to guests for data collection.</p>
                    </div>
                    <div className='p-3 bg-[#e8e8e8] rounded-lg'>
                        <h3 className='text-base sm:text-lg text-center mb-2'>3. Share survey</h3>
                        <p className='text-left text-sm sm:text-base'>Share your survey via email, link or QR code to reach a wide audience and encourage participation.</p>
                    </div>
                    <div className='p-3 bg-[#e8e8e8] rounded-lg'>
                        <h3 className='text-base sm:text-lg text-center mb-2'>4. Analyse results</h3>
                        <p className='text-left text-sm sm:text-base'>Dive into the collected data with powerful analytical tools, gaining actionable insights to enhance guest satisfaction and improve overall experience.</p>
                    </div>
                </div>
                <div>
                    <p>You will find <strong>short introduction</strong> like this on <strong>each page</strong>.</p>
                </div>
                <div className='flex justify-between items-center gap-4'>
                    <span className='text-sm sm:text-base'>Link to official documentation: <a href='https://megatrendgrupa.atlassian.net/wiki/external/MTA2NDc4YTAwZTE3NDMwMWEwMWViYmJiOTRmNjk0NDE' target='_blank' className='underline text-blue-400 hover:text-blue-800'>Link</a></span>
                    
                    <div className='flex flex-col gap-4 justify-end'>
                        <div className="flex items-end sm:items-center space-x-2 flex-col sm:flex-row">
                            <label htmlFor="off" className='text-sm order-1 sm:order-none text-right'>Turn off onboarding</label>
                            <Switch 
                                id="off" 
                                checked={checked}
                                onCheckedChange={(isChecked:boolean) => setChecked(isChecked)}
                            />
                        </div>
                        <div className='flex justify-end'>
                            <button className="bg-[#004E96] p-2 px-4 rounded-lg text-sm sm:text-base text-white ml-4 sm:ml-0" onClick={onContinue}>
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
