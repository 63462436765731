import { useStep } from 'context/stepContext';
import React, { useState } from 'react'
import { stepSetAction } from 'reducers/onboardingReducer';
import { Switch } from "@/components/ui/switch"

export default function OnboardingStepThreeLibrary() {

    const [checked, setChecked] = useState<boolean>(false)
    
    const { dispatch } = useStep();

    function onContinue(){
        if(checked){
            dispatch(stepSetAction({ should_show_onboarding: false }));
        } else {
            dispatch(stepSetAction({ stepthree: true }));
        }
    }

    return (
        <div className='absolute inset-0 flex bg-black/50 z-50 justify-center items-center px-10'>
            <div className='max-w-sm p-4 rounded-lg flex flex-col bg-[#F5F5F5] gap-14 text-sm sm:text-base'>
                <div className='flex flex-col gap-4'>
                    <p>Consider library as your <strong>templates library</strong>. While you are creating or editing your surveys, you can <strong>use</strong> any of your created libraries to <strong>speed up</strong> the process.</p>
                    <p>We have created some demo libraries for you that you can use any time.</p>
                </div>
                <div className='flex flex-col gap-4 justify-end items-end'>
                    <div className="flex items-end space-x-2 flex-col sm:flex-row">
                        <label htmlFor="off" className='text-sm order-1 sm:order-none text-right'>Turn off onboarding</label>
                        <Switch 
                            id="off" 
                            checked={checked}
                            onCheckedChange={(isChecked:boolean) => setChecked(isChecked)}
                        />
                    </div>
                    <div className='flex justify-end'>
                        <button className="bg-[#004E96] p-2 px-4 rounded-lg text-sm sm:text-base text-white ml-4 sm:ml-0" onClick={onContinue}>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
