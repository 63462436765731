import classNames from 'classnames';
import { useLanguageContext } from 'context';
import { IQuestion } from 'services/surveys/interfaces';
import {
	EmojiRatingChoice,
	IGeoPointAnswer
} from 'services/submissions/interfaces';
import { range } from 'utils';

import { ReactComponent as SvgCheckmark } from 'assets/icons/check.svg';
import { ReactComponent as SvgSad } from 'assets/icons/sad.svg';
import { ReactComponent as SvgNeutral } from 'assets/icons/neutral.svg';
import { ReactComponent as SvgSmile } from 'assets/icons/smile.svg';
import { ReactComponent as SvgExtatic } from 'assets/icons/extatic.svg';

export const SubmissionEntryDisplay = ({
	question,
	answer,
	translatedAnswer,
	translatedFrom
}: {
	question: IQuestion;
	answer: IGeoPointAnswer | number | string | null;
	translatedAnswer: string | null;
	translatedFrom: string | null;
}) => {
	const { defaultLanguage } = useLanguageContext();
	const { label, type } = question;
	
	return (
		<div className="flex flex-col mb-6 border border-gray-300 bg-gray-50 px-4 py-6 rounded-lg">
			<div className="flex justify-between mb-4">
				<h3 className="text-xl font-bold text-gray-700">
					{label?.[defaultLanguage]}
				</h3>
				<span className="h-max mt-3 text-xs text-gray-400 capitalize">
					{type.replaceAll('_', ' ')}
				</span>
			</div>
			<AnswerTypeSwitch question={question} answer={answer} translatedAnswer={translatedAnswer} translatedFrom={translatedFrom} />
		</div>
	);
};

const AnswerTypeSwitch = ({
	question,
	answer,
	translatedAnswer,
	translatedFrom
}: {
	question: IQuestion;
	answer: IGeoPointAnswer | number | string | null | Record<string,string>;
	translatedAnswer: string |null;
	translatedFrom: string |null;
}) => {
	const { defaultLanguage } = useLanguageContext();
	const { type } = question;

	if (answer === null || answer === undefined) {
		return <span>Not answered</span>;
	}

	if (['select_one', 'select_multiple', 'sex'].includes(type)) {
		return (
			<SelectType
				question={question}
				answer={answer as string}
				defaultLanguage={defaultLanguage}
			/>
		);
	}
	if (type === 'matrix_rating')
		return <MatrixRatingType answers={
			answer as Record<string,string>} 
			question={question}
			defaultLanguage={defaultLanguage} />;
	if (type === 'emoji_rating')
		return <EmojiRatingType answer={answer as EmojiRatingChoice} />;
	if (type === 'nps') return <NPSType answer={answer as string} />;
	if (type === 'rating') return <RatingType answer={answer as string} />;
	if (type === 'image')
		return <img src={answer as string} className="w-40 rounded-md mt-1" />;
	if (type === 'geopoint')
			return <GeopointType answer={answer as string} />;
	if (type === 'range')
		return <RangeType question={question} answer={answer as string} />;
	if (type === 'date')
		return <p>{new Date(String(answer)).toLocaleDateString()}</p>;
	if (type === 'text')
		return <TextType answer={answer as string} translatedAnswer={translatedAnswer?translatedAnswer:''} translatedFrom={translatedFrom?translatedFrom:''}/>;
	return <p>{answer as string}</p>;
};

const SelectType = ({
	answer,
	question,
	defaultLanguage
}: {
	answer: string;
	question: IQuestion;
	defaultLanguage: string;
}) => {
	const { choices, type } = question;
	return (
		<p className="flex flex-col gap-3">
			{choices.map((choice, i) => {
				const { name, label } = choice;
				const answers = answer.split(" ");
				const isSelected = answers.includes(name);
				const isMultiple = type === 'select_multiple';

				return (
					<span key={i} className={`flex items-center`}>
						<span
							className={classNames(
								'flex items-center justify-center w-4 h-4 mr-2.5',
								{
									'rounded-full': !isMultiple,
									rounded: isMultiple,
									'bg-indigo-500 outline outline-2 outline-indigo-500 outline-offset-2':
										isMultiple && isSelected,
									'bg-white': !isSelected,
									'border-[5px] border-indigo-500 outline outline-2 outline-indigo-500 outline-offset-2':
										!isMultiple && isSelected,
									'border border-gray-300': !isSelected
								}
							)}
						>
							{isMultiple && isSelected && (
								<SvgCheckmark className="text-white w-3 h-3" />
							)}
						</span>
						{label?.[defaultLanguage]}
					</span>
				);
			})}
		</p>
	);
};

const EmojiRatingType = ({ answer }: { answer: EmojiRatingChoice }) => {
	const emojis: Record<
		EmojiRatingChoice,
		React.FunctionComponent<React.SVGProps<SVGSVGElement>>
	> = {
		choice_0: SvgSad,
		choice_1: SvgNeutral,
		choice_2: SvgSmile,
		choice_3: SvgExtatic
	};
	return (
		<div className="flex items-center gap-8">
			{(Object.keys(emojis) as EmojiRatingChoice[]).map(emoji => {
				const Emoji = emojis[emoji];
				return (
					<div className="flex items-center">
						<span
							className={`w-4 h-4 bg-white rounded-full mr-4 ${
								answer === emoji
									? 'border-[5px] border-indigo-500 outline outline-2 outline-offset-2 outline-indigo-500'
									: 'border border-gray-500'
							}`}
						></span>
						<Emoji
							className={
								answer === emoji
									? `outline outline-[3px] outline-offset-[3px] outline-indigo-500 rounded-full`
									: ''
							}
						/>
					</div>
				);
			})}
		</div>
	);
};

const NPSType = ({ answer }: { answer: string }) => {
	return (
		<div className="flex items-center gap-4">
			{range(0, 11).map(i => {
				
				return (
					<div key={i} className="flex flex-col items-center">
						<span
							className={classNames(
								'flex items-center justify-center w-4 h-4 mb-2 rounded-full bg-white',
								{
									'border-[5px] border-indigo-500 outline outline-2 outline-indigo-500 outline-offset-2':
										i === Number(answer?.substring(answer.indexOf('_')+1)),
									'border border-gray-300': i !== Number(answer?.substring(answer.indexOf('_')+1))
								}
							)}
						></span>
						<span>{i}</span>
					</div>
				);
			})}
		</div>
	);
};

const RatingType = ({ answer }: { answer: string }) => {
	return (
		<div className="flex items-center gap-4">
			{range(0, 5).map(i => {
				return (
					<span
						key={i}
						className={classNames(
							'flex items-center justify-center py-1.5 px-6 rounded-2xl bg-white text-base',
							{
								'border-2 border-indigo-500':
									i === Number(answer?.split('_')[1]),
								'border border-gray-300': i !== Number(answer?.split('_')[1])
							}
						)}
					>
						{i + 1}
					</span>
				);
			})}
		</div>
	);
};

const MatrixRatingType = ({ answers, question, defaultLanguage }: { answers:Record<string,string> , question: IQuestion, defaultLanguage:string }) => {
	return (
		<div className="flex flex-col gap-4">
		{question.choices.map((choice, index) => (
			<div key={index} className="flex items-center gap-4">
				<div className="w-80">
					<label>{choice.label[defaultLanguage]}</label>
				</div>
				{range(0, 5).map(i => (
					<span
						key={i}
						className={classNames(
							'flex items-center justify-center py-1.5 px-6 rounded-2xl bg-white text-base',
							{
								'border-2 border-indigo-500':
									i === Number(answers[choice.name]?.split('_')[1]),
								'border border-gray-300': i !== Number(answers[choice.name]?.split('_')[1])
							}
						)}
					>
						{i + 1}
					</span>
				))}
			</div>
		))}
	</div>
	);
};

const GeopointType = ({ answer }: { answer: string }) => {
	const [longitude, latitude, altitude] = answer.split(',');
	return (
		<p className="flex flex-col gap-0.5">
			<span>Longitude: {longitude}</span>
			<span>Latitude: {latitude}</span>
			<span>Altitude: {altitude}</span>
		</p>
	);
};

const TextType = ({
	answer,
	translatedAnswer,
	translatedFrom
  }: {
	answer: string;
	translatedAnswer: string;
	translatedFrom: string;
  }) => {
	return (
	  <p className="flex flex-col gap-0.5">
		<span>{answer}</span>
		{translatedFrom && (
		  <>
			<span className="text-gray-500 italic mt-5">
			  Translated from {translatedFrom}
			</span>
			<span className="text-gray-500 italic">{translatedAnswer}</span>
		  </>
		)}
	  </p>
	);
  };
  

const RangeType = ({
	question,
	answer
}: {
	question: IQuestion;
	answer: string;
}) => {
	const { parameters } = question;
	return (
		<p className="flex flex-col w-1/2">
			<div className="flex items-center justify-between bg-gray-300 h-[3px] rounded-full relative">
				<span className="block w-4 h-4 rounded-full border border-gray-300 bg-white"></span>

				<span
					style={{
						marginLeft: `${answer}%`
					}}
					className="block w-4 h-4 rounded-full border-[5px] border-indigo-500 bg-white absolute top-1/2 -translate-y-1/2"
				></span>

				<span className="block w-4 h-4 rounded-full border border-gray-300 bg-white"></span>
			</div>
			<div className="flex items-center justify-between mt-2 relative">
				<span className="flex items-center justify-start w-8 h-8 text-gray-500">
					{parameters?.split(';')[0].split('=')[1]}
				</span>

				<span
					style={{
						marginLeft: `${answer}%`
					}}
					className={`flex items-center justify-center w-4 absolute top-1/2 -translate-y-1/2 text-indigo-500 font-bold`}
				>
					{answer}
				</span>

				<span className="flex items-center justify-end w-8 h-8 text-gray-500">
					{parameters?.split(';')[1].split('=')[1]}
				</span>
			</div>
		</p>
	);
};
