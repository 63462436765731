import { IOption } from 'components/form-elements';
import { FieldArrayRenderProps } from 'formik';
import {
	INotification,
	INotificationType,
	IQuestionRule,
	IRuleQuestion
} from 'services/notifications';
import { IQuestion, ISurvey, IChoice } from 'services/surveys/interfaces';
import { capitalize } from 'utils';

/* NOTIFICATIONS */

export const getAvailableNotificationTypes = (
	notificationTypes: INotificationType[],
	notifications: INotification[],
	item: INotification
): IOption[] => {
	const notificationTypesOptions = notificationTypes.map(
		(notificationType: INotificationType) => {
			return {
				value: Number(notificationType.notification_type_id),
				display: capitalize(notificationType.type)
			};
		}
	);

	const availableOptions = notificationTypesOptions.filter(
		option =>
			!notifications.some(
				notification => notification.notification_type_id === option.value
			) || item.notification_type_id === option.value
	);

	return availableOptions;
};

export const getUniqueContactId = (notifications: INotification[]): number => {
	const recipientsArray = Object.values(notifications)
		.map(notification => notification.contacts)
		.flat();

	return recipientsArray[recipientsArray.length - 1].contact_id + 1;
};

export const addNewNotification = (
	notificationTypes: INotificationType[],
	notifications: INotification[],
	helper: FieldArrayRenderProps
): void => {
	const nextUnusedTypeId = notificationTypes.find(
		notificationType =>
			!notifications.some(
				notification =>
					notification.notification_type_id ===
					notificationType.notification_type_id
			)
	)?.notification_type_id;

	return helper.push({
		notification_type_id: nextUnusedTypeId,
		contacts: [
			{
				contact_id: getUniqueContactId(notifications),
				contact: ''
			}
		]
	});
};

export const showAddNewNotificationTypeButton = (
	notificationTypes: INotificationType[],
	notifications: INotification[]
): boolean => {
	const hasUnusedTypes = notificationTypes.some(
		notificationType =>
			!notifications.some(
				notification =>
					notification.notification_type_id ===
					notificationType.notification_type_id
			)
	);

	return hasUnusedTypes;
};

/* SURVEYS */

export const getAvailableSurveys = (
	surveys: ISurvey[],
	question_rules: IQuestionRule[],
	item: IQuestionRule
): IOption[] => {
	const surveysOptions = surveys.map((survey: ISurvey) => {
		return {
			value: Number(survey.survey_id),
			display: survey.survey_name
		};
	});

	const availableOptions = surveysOptions.filter(
		option =>
			!question_rules.some(
				question_rule => question_rule.survey_id === option.value
			) || item.survey_id === option.value
	);

	return availableOptions;
};

export const addNewSurvey = (
	surveys: ISurvey[],
	question_rules: IQuestionRule[],
	helper: FieldArrayRenderProps
): void => {
	const nextUnusedSurveyId = surveys.find(
		survey =>
			!question_rules.some(
				question_rule =>
					Number(question_rule.survey_id) === Number(survey.survey_id)
			)
	)?.survey_id;

	return helper.push({
		survey_id: Number(nextUnusedSurveyId),
		questions: [
			{
				question_id: 0,
				comparator: '',
				value: ''
			}
		]
	});
};

export const showAddNewSurveyButton = (
	surveys: ISurvey[],
	question_rules: IQuestionRule[]
): boolean => {
	const hasUnusedTypes = surveys.some(
		survey =>
			!question_rules.some(
				question_rule => question_rule.survey_id === Number(survey.survey_id)
			)
	);

	return hasUnusedTypes;
};

/* QUESTIONS */

export const getAvailableQuestions = (
	allQuestions: IQuestion[],
	ruleQuestions: IRuleQuestion[],
	item: IRuleQuestion,
	defaultLanguage: string
): IOption[] => {
	const questionsOptions = allQuestions.map((question: IQuestion) => {
		return {
			value: Number(question.question_id),
			display: question.label?.[defaultLanguage],
			type: question.type,
			analytics_type: question.analytics_type?.toString() || null
		};
	});

	const availableOptions = questionsOptions.filter(
		option =>
			!ruleQuestions.some(
				ruleQuestion => ruleQuestion.question_id === option.value
			) || item.question_id === option.value
	);

	return availableOptions;
};

/* QUESTION OPTIONS */

export const getSelectedQuestionChoices = (
	allQuestions: IQuestion[],
	question: IRuleQuestion,
	defaultLanguage: string,
	question_type: string,
	options_for_type: IOption[]
): IOption[] => {
	if (question.comparator !== undefined && question.comparator ==='is_answered')
	{
		return [{ 
			value: 'true',
			display: 'True'
		}];
	}
	const availableOptions : IOption[] = [{
			value: '',
			display: 'Select value'
	}] 
    if(question_type==='select_one') {
		const q = allQuestions.find(q => q.question_id===question.question_id);

		if(q!==undefined){
			 availableOptions.push(...q.choices.map((choice: IChoice) => {
				return {
					value: choice.name,
					display: choice.label[defaultLanguage]
				};
			}));
			return availableOptions;
		}
	}else{
		availableOptions.push(...options_for_type);
	}	
	return availableOptions;
};

export const addNewQuestion = (
	allQuestions: IQuestion[],
	ruleQuestions: IRuleQuestion[],
	helper: FieldArrayRenderProps
): void => {
	const nextUnusedQuestionId = allQuestions.find(
		question =>
			!ruleQuestions.some(
				ruleQuestion =>
					Number(ruleQuestion.question_id) === Number(question.question_id)
			)
	)?.question_id;

	return helper.push({
		question_id: nextUnusedQuestionId,
		comparator: '',
		value: ''
	});
};

export const showAddNewQuestionButton = (
	allQuestions: IQuestion[],
	ruleQuestions: IRuleQuestion[]
): boolean => {
	const hasUnusedTypes = allQuestions.some(
		question =>
			!ruleQuestions.some(
				ruleQuestion =>
					ruleQuestion.question_id === Number(question.question_id)
			)
	);

	return hasUnusedTypes;
};
