import React, { createContext, useReducer, useContext } from 'react';
// import { IStepState, stepReducer, StepActions, stepSetAction } from './stepReducer';
import { IStepState, initialStepState, stepReducer } from 'reducers/onboardingReducer';

interface IStepContext {
  state: IStepState;
  dispatch: React.Dispatch<any>;
}

const StepContext = createContext<IStepContext | undefined>(undefined);

export const useStep = () => {
  const context = useContext(StepContext);
  if (!context) {
    throw new Error('useStep must be used within a StepProvider');
  }
  return context;
};

export const StepProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(stepReducer, initialStepState);

  return (
    <StepContext.Provider value={{ state, dispatch }}>
      {children}
    </StepContext.Provider>
  );
};
