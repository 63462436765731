import { useStep } from 'context/stepContext';
import React, { useState } from 'react'
import { stepSetAction } from 'reducers/onboardingReducer';
import { Switch } from "@/components/ui/switch"

export default function OnboardingStepSixAnalytics() {

    const [checked, setChecked] = useState<boolean>(false)
    
    const { dispatch } = useStep();

    function onContinue(){
        if(checked){
            dispatch(stepSetAction({ should_show_onboarding: false }));
        } else {
            dispatch(stepSetAction({ stepsix: true }));
        }
    }

    return (
        <div className='absolute inset-0 flex bg-black/50 z-50 justify-center items-center px-10'>
            <div className='max-w-sm p-4 rounded-lg flex flex-col bg-[#F5F5F5] gap-14 text-sm sm:text-base'>
                <div className='flex flex-col gap-4'>
                    <p>The <strong>analytics page</strong> is a dedicated area where users can <strong>access and analyse data</strong> collected from surveys.</p>
                    <p>It includes various <strong>visualizations, charts, graphs</strong> and <strong>metrics</strong> derived from survey responses, allowing users to analyse trends, track performance, and identify areas for improvement.</p>
                    <p>The analytics pane enables users to <strong>gain actionable insights</strong> into customer feedback, satisfaction levels, and overall performance, empowering them to <strong>make informed decisions</strong> and drive positive outcomes for their business.</p>
                </div>
                <div className='flex flex-col gap-4 justify-end items-end'>
                    <div className="flex items-end sm:items-center space-x-2 flex-col sm:flex-row">
                        <label htmlFor="off" className='text-sm order-1 sm:order-none text-right'>Turn off onboarding</label>
                        <Switch 
                            id="off" 
                            checked={checked}
                            onCheckedChange={(isChecked:boolean) => setChecked(isChecked)}
                        />
                    </div>
                    <div className='flex justify-end'>
                        <button className="bg-[#004E96] p-2 px-4 rounded-lg text-sm sm:text-base text-white ml-4 sm:ml-0" onClick={onContinue}>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
