import React from 'react';

import { FieldSelect } from 'components/formik-elements';
import { isEmpty, range } from 'utils';
import { FieldArrayRenderProps, FormikErrors, FormikProps } from 'formik';
import {
	INotificationRule,
	IQuestionRule,
	IRuleQuestion
} from 'services/notifications';
import { getAvailableQuestions, getSelectedQuestionChoices } from '..';
import { ReactComponent as SvgClose } from 'assets/icons/close.svg';

const valueOptions = {
	text: [
		{
			value: 'negative',
			display: 'Negative'
		},
		{
			value: 'neutral',
			display: 'Neutral'
		},
		{
			value: 'mixed',
			display: 'Mixed'
		},
		{
			value: 'positive',
			display: 'Positive'
		}
	],
	emoji_rating: [
		{
			value: 'choice_0',
			display: 'Sad'
		},
		{
			value: 'choice_1',
			display: 'Neutral'
		},
		{
			value: 'choice_2',
			display: 'Smile'
		},
		{
			value: 'choice_3',
			display: 'Extatic'
		}
	],
	nps: range(0, 11).map(item => {
		return {
			value: String(item),
			display: String(item)
		};
	}),
	rating: range(1, 6).map(item => {
		return {
			value: String(item),
			display: String(item)
		};
	}),
	select_one:[
	],
	select_multiple:[
	],
	integer:[
	],
	decimal:[
	],
	range:[
	],
	geopoint:[
	],
	date:[
	],
	image:[
	],
	email:[
	],
	country:[
	],
	age:[
	],
	sex:[
	], 
	select_one_img:[		
	], 
	select_multiple_img:[
	]
};

const comparatorOptions = {
	text: [
		{
			value: '=',
			display: 'Is equal to'
		},
		{
			value: '!=',
			display: 'Is not equal to'
		},
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	emoji_rating: [
		{
			value: '=',
			display: 'Is equal to'
		},
		{
			value: '!=',
			display: 'Is not equal to'
		},
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	nps: [
		{
			value: '=',
			display: 'Is equal to'
		},
		{
			value: '!=',
			display: 'Is not equal to'
		},
		{
			value: '<',
			display: 'Is less than'
		},
		{
			value: '>',
			display: 'Is greater than'
		},
		{
			value: '<=',
			display: 'Is less than or equal to'
		},
		{
			value: '>=',
			display: 'Is greater than or equal to'
		},
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	rating: [
		{
			value: '=',
			display: 'Is equal to'
		},
		{
			value: '!=',
			display: 'Is not equal to'
		},
		{
			value: '<',
			display: 'Is less than'
		},
		{
			value: '>',
			display: 'Is greater than'
		},
		{
			value: '<=',
			display: 'Is less than or equal to'
		},
		{
			value: '>=',
			display: 'Is greater than or equal to'
		},
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	select_one: [
		{
			value: '=',
			display: 'Is equal to'
		},
		{
			value: '!=',
			display: 'Is not equal to'
		},
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	select_multiple:[
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	integer:[
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	decimal:[
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	range:[
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	geopoint:[
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	date:[
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	image:[
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	email:[
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	country:[
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	age:[
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	sex:[
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	select_one_img: [
		{
			value: '=',
			display: 'Is equal to'
		},
		{
			value: '!=',
			display: 'Is not equal to'
		},
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	],
	select_multiple_img:[
		{
			value: 'is_answered',
			display: 'Is answered'
		}
	]
};

export interface IQuestionRuleQuestionProps {
	questionOptions: any[];
	questionRule: IQuestionRule;
	ruleIndex: number;
	question: IRuleQuestion;
	questionIndex: number;
	formProps: FormikProps<INotificationRule>;
	questionsHelper: FieldArrayRenderProps;
	surveyDefaultLanguage: string;
}

export const QuestionRuleQuestion: React.FC<IQuestionRuleQuestionProps> = ({
	questionOptions,
	questionRule,
	ruleIndex,
	question,
	questionIndex,
	formProps,
	questionsHelper,
	surveyDefaultLanguage
}) => {

	const [questionType, setQuestionType] = React.useState<
	'select_one'
	| 'select_multiple'
	| 'emoji_rating'
	| 'text'
	| 'integer'
	| 'decimal'
	| 'range'
	| 'geopoint'
	| 'date'
	| 'image'
	| 'email'
	| 'country'
	| 'age'
	| 'sex'
	| 'nps'
	| 'rating'
	| 'select_one_img'
	| 'select_multiple_img'
	>('text');

	React.useEffect(() => {
		if (!isEmpty(questionOptions) && question.question_id) {
			setQuestionType(
				questionOptions.find(item => item.question_id === question.question_id)
					.type
			);
		}
	}, [questionOptions, question.question_id]);

	if (!questionOptions || isEmpty(questionOptions) || !questionType) {
		return (
			<p className="text-sm text-gray-500 mt-4">
				Selected survey has no supported questions
			</p>
		);
	}

	return (
		<div className="flex justify-between space-x-2">
			<FieldSelect
				className="w-1/3"
				name={`question_rules.${ruleIndex}.questions.${questionIndex}.question_id`}
				label="Select question"
				id={`question_rules.${ruleIndex}.questions.${questionIndex}.question_id`}
				options={[
					{
						value: '',
						display: 'Select question'
					},
					...getAvailableQuestions(
					questionOptions,
					questionRule.questions,
					question,
					surveyDefaultLanguage
					)
				]}
				onChange={q => {
					setQuestionType(q.type as any);
					formProps.setFieldValue(
						`question_rules.${ruleIndex}.questions.${questionIndex}.comparator`,
						''
					);
					formProps.setFieldValue(
						`question_rules.${ruleIndex}.questions.${questionIndex}.value`,
						''
					);
				}}
				error={
					(
						(
							formProps.errors?.question_rules?.[
								ruleIndex
							] as FormikErrors<IQuestionRule>
						)?.questions?.[questionIndex] as FormikErrors<IRuleQuestion>
					)?.question_id && formProps.submitCount
						? (
								(
									formProps.errors?.question_rules?.[
										ruleIndex
									] as FormikErrors<IQuestionRule>
								)?.questions?.[questionIndex] as FormikErrors<IRuleQuestion>
						  )?.question_id
						: ''
				}
			/>
			<FieldSelect
				className="w-1/3"
				name={`question_rules.${ruleIndex}.questions.${questionIndex}.comparator`}
				label="Comparator"
				id={`question_rules.${ruleIndex}.questions.${questionIndex}.comparator`}
				options={[
					{
						value: '',
						display: 'Select comparator'
					},
					...comparatorOptions[questionType]
				]}
				error={
					(
						(
							formProps.errors?.question_rules?.[
								ruleIndex
							] as FormikErrors<IQuestionRule>
						)?.questions?.[questionIndex] as FormikErrors<IRuleQuestion>
					)?.comparator && formProps.submitCount
						? (
								(
									formProps.errors?.question_rules?.[
										ruleIndex
									] as FormikErrors<IQuestionRule>
								)?.questions?.[questionIndex] as FormikErrors<IRuleQuestion>
						  )?.comparator
						: ''
				}
			/>

			<FieldSelect
				className="w-1/3"
				name={`question_rules.${ruleIndex}.questions.${questionIndex}.value`}
				label="Value"
				id={`question_rules.${ruleIndex}.questions.${questionIndex}.value`}
				options={[
					...getSelectedQuestionChoices(questionOptions, question, surveyDefaultLanguage, questionType,valueOptions[questionType])
				]}
				error={
					(
						(
							formProps.errors?.question_rules?.[
								ruleIndex
							] as FormikErrors<IQuestionRule>
						)?.questions?.[questionIndex] as FormikErrors<IRuleQuestion>
					)?.value && formProps.submitCount
						? (
								(
									formProps.errors?.question_rules?.[
										ruleIndex
									] as FormikErrors<IQuestionRule>
								)?.questions?.[questionIndex] as FormikErrors<IRuleQuestion>
						  )?.value
						: ''
				}
			/>

			{questionRule.questions.length > 1 && (
				<button
					type="button"
					onClick={() => questionsHelper.remove(questionIndex)}
					className="flex-none w-8 h-8 p-2 mt-7 text-blue-700 text-lg flex justify-center items-center bg-blue-50 hover:bg-blue-200 rounded-full"
				>
					<SvgClose />
				</button>
			)}
		</div>
	);
};
