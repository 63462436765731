import React from 'react'
import { updateUserInfo, useGetUserInfoQuery } from 'services/users';
import { useNavigate } from "react-router-dom";


export default function OnboardingButton() {

    const {
        data: user,
    } = useGetUserInfoQuery();    

    const navigate = useNavigate();

    function onSubmit(){
        if(user){
            const values = {
                username: user.username,
                first_name: user.first_name, 
                last_name: user.last_name, 
                email: user.email, 
                show_onboarding: true, 
            }
            
            updateUserInfo(values)
                .then(() => {
                        navigate("/surveys");
                    })
		}
	}
    
    return (
        <div className='pb-8'>
            <p>Would you like to start the onboarding proccess?</p>
            <div className='flex justify-start'>
                <button className="bg-[#004E96] p-2 px-4 mt-2 rounded-lg text-white" onClick={onSubmit}>
                    Start onboarding
                </button>
            </div>
        </div>
    )
}