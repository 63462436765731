import { FieldArrayRenderProps, FieldHelperProps } from 'formik';
import { IQuestion, ISurveyUI } from 'services/surveys/interfaces';
import { addQuestionToGeneral, createNewQuestion, getQuestionCount } from '.';

export const addSection = (
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>,
	survey: ISurveyUI
) => {
	getFieldHelpers('sections').setValue([
		...survey.sections,
		{
			type: 'begin_group',
			name: `gb_${getQuestionCount(survey)}`,
			appearance: 'single',
			questions: [createNewQuestion(survey)],
			choices: [],
			skip_logic: [],
			is_sensitive:false
		}
	]);
};

export const deleteSection = (
	questions: IQuestion[],
	arrayHelpers: FieldArrayRenderProps,
	sectionIndex: number
) => {
	questions.forEach(question => {
		addQuestionToGeneral(arrayHelpers, question);
	});
	arrayHelpers.remove(sectionIndex);
};
